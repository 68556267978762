import { useEffect, useState } from 'react'
import './toast.component.css'

const ToastComponent = () => {
  const [toastClass, setToastClass] = useState('prg-toast')
  const [message, setMessage] = useState()

  useEffect(() => {
    const handleToastRequest = (e) => {
      const {
        detail: { message, style },
      } = e
      setMessage(message)

      // Show after 250ms
      setTimeout(() => {
        setToastClass(`prg-toast ${style} show`)
      }, 250)

      // Hide after 4s
      setTimeout(() => {
        setToastClass('prg-toast')
      }, 4250)
    }

    document.addEventListener('EVENTS.SHOW_TOAST', handleToastRequest)

    return () =>
      document.removeEventListener('EVENTS.SHOW_TOAST', handleToastRequest)
  }, [])

  return <div className={toastClass}>{message}</div>
}

export default ToastComponent
