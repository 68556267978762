import { ASSETS_URL } from '../src/constants'

const isLocal = window.location.hostname.indexOf('localhost') > -1

const isLocalNetwork = window.location.hostname.indexOf('192.168.68') > -1

const rootUrl = isLocal
  ? 'http://localhost:3000'
  : isLocalNetwork
  ? 'http://192.168.68.53:3000'
  : 'https://api.pihakis-app.com'
const apiUrl = `${rootUrl}/api/`

class API {
  static getRootUrl = () => {
    return rootUrl
  }

  static getOptimizedImage = (image) => {
    let path = image
    if (image.sizes?.largeSquare?.url) {
      path = image.sizes.largeSquare
    } else if (image.sizes?.mediumSquare?.url) {
      path = image.sizes.mediumSquare
    }

    return this.getMediaAssetUrl(path.url)
  }

  static getMediaAssetUrl = (path) => {
    if (!path) return ''

    const mediaPath = `${rootUrl}/media`
    return `${ASSETS_URL}${path.replace(mediaPath, '')}`
  }

  static getContent = (contentType, contentId, contentSlug) => {
    return new Promise(async (resolve, reject) => {
      try {
        const depth = 2
        let url = `${apiUrl}${contentType}`
        if (contentId) {
          url += `/${contentId}?depth=${depth}`
        } else if (contentSlug) {
          url += `?where[slug][equals]=${contentSlug}&depth=${depth}`
        }

        const res = await fetch(url, {
          headers: {
            Authorization: `users API-Key 831282d0-eb45-4193-b8b5-f868a3ee5ae0`,
          },
        }).then((r) => r.json())

        if (contentSlug) {
          return resolve(res.docs[0])
        }

        resolve(res)
      } catch (e) {
        console.error(e)
        reject(e)
      }
    })
  }

  static postContent = (path, body) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${apiUrl}${path}`
        const res = await fetch(url, {
          method: 'post',
          headers: {
            Authorization: `users API-Key 831282d0-eb45-4193-b8b5-f868a3ee5ae0`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        }).then((r) => r.json())

        resolve(res)
      } catch (e) {
        console.error(e)
        reject(e)
      }
    })
  }
}

export default API
