import './content-line-break.component.css'

const ContentLineBreakComponent = (props) => {
  const { includeExtraPadding, disableLine } = props
  let className = 'prg-content-line-break'

  if (includeExtraPadding && includeExtraPadding !== 'none') {
    className += ` extra-${includeExtraPadding}`
  }

  if (disableLine) {
    className += ' hide-line'
  }

  return <div className={className}></div>
}

export default ContentLineBreakComponent
