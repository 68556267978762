import API from '../../../api'
import './people-grid.component.css'

const PeopleGridComponent = (props) => {
  const { heading, subHeading, people } = props

  const handleClickPerson = (e, person) => {
    e.stopPropagation()

    if (!person.bio) return

    // Dispatch event
    document.dispatchEvent(
      new CustomEvent('EVENTS.SHOW_PERSON', { detail: person }),
    )
  }

  return (
    <div className={`prg-people-grid`}>
      <div className="prg-people-grid-container">
        {heading && <h1>{heading}</h1>}
        {subHeading && <h4>{subHeading}</h4>}
        <div className="prg-people-grid-items">
          {people.map((i) => {
            const { id, name, title, photo, bio } = i
            return (
              <div
                key={id}
                className={
                  bio
                    ? 'prg-people-grid-item actionable'
                    : 'prg-people-grid-item'
                }
                onClick={(e) => handleClickPerson(e, i)}
              >
                <div key={id} className="prg-people-grid-photo">
                  {photo?.url && (
                    <img
                      src={API.getMediaAssetUrl(photo.url)}
                      alt={photo.altText}
                    />
                  )}
                </div>
                {name && <h5>{name}</h5>}
                {title && <h6>{title}</h6>}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default PeopleGridComponent
