import { useEffect, useRef } from 'react'
import './preloader.component.css'
import { gsap } from 'gsap'

const PreloaderComponent = (props) => {
  const { restaurant } = props
  const timelineRef = useRef()

  useEffect(() => {
    gsap.fromTo(
      '.prg-preloader-container',
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.4,
      },
    )

    const { from, to } = restaurant.styles.preloaderAnimation
    gsap.fromTo('.prg-preloader-container svg', from, {
      ...to,
      onComplete: () => {
        const { from, to } = restaurant.styles.preloaderPulseAnimation

        // Pulse logo infinitely
        timelineRef.current = gsap.timeline({
          repeat: -1,
          repeatDelay: 0,
        })

        timelineRef.current.set('.prg-preloader-container svg', {
          scale: 1,
          opacity: 1,
        })
        timelineRef.current.to('.prg-preloader-container svg', from)
        timelineRef.current.to('.prg-preloader-container svg', to)
      },
    })
  }, [restaurant])

  // Listen for exit
  useEffect(() => {
    const handleExit = () => {
      if (timelineRef.current) {
        timelineRef.current.pause()
      }

      const preloaderExitAnimation = restaurant.styles.preloaderExitAnimation
      gsap.to('.prg-preloader-container svg', preloaderExitAnimation)
      gsap.to('.prg-preloader-container', {
        opacity: 0,
        duration: 0.6,
        delay: 0.1,
      })
    }

    document.addEventListener('EVENTS.HIDE_PRELOADER', handleExit)
    return () =>
      document.removeEventListener('EVENTS.HIDE_PRELOADER', handleExit)
  }, [restaurant])

  return (
    <div
      className="prg-preloader-container"
      style={{ backgroundColor: restaurant.styles.primaryColor }}
    >
      {restaurant.icons.logoIcon}
    </div>
  )
}

export default PreloaderComponent
