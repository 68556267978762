import { useLayoutEffect } from 'react'
import API from '../../../api'
import HtmlRendererComponent from '../html-renderer/html-renderer.component'
import './call-to-action-block.component.css'
import { gsap } from 'gsap'
import useCheckForMobileHook from '../../../hooks/useCheckForMobile'
import Utils from '../../../utils'
import { useNavigate } from 'react-router-dom'

const CallToActionBlockComponent = (props) => {
  const {
    heading,
    brandStyle,
    image,
    copy,
    buttons,
    restaurant,
    illustration,
  } = props
  const isMobile = useCheckForMobileHook()
  const navigate = useNavigate()

  // Animation
  useLayoutEffect(() => {
    if (isMobile || restaurant.styles.disableParallax) return

    const scrollParams = {
      once: true,
      trigger: '.prg-cta-block',
      start: 'top 90%',
      end: '+=90%',
      scrub: 1,
    }

    setTimeout(() => {
      gsap.fromTo(
        '.prg-cta-block',
        {
          yPercent: 30,
        },
        {
          yPercent: 0,
          scrollTrigger: scrollParams,
        },
      )

      gsap.fromTo(
        '.prg-cta-block h2',
        {
          yPercent: 60,
        },
        {
          yPercent: 0,
          scrollTrigger: { ...scrollParams, scrub: 2 },
        },
      )

      gsap.fromTo(
        '.prg-cta-block-btns',
        {
          yPercent: -200,
        },
        {
          yPercent: 0,
          scrollTrigger: { ...scrollParams, scrub: 2 },
        },
      )

      // Do wrapper if it exists
      const wrapper = document.querySelector('.prg-cta-block-wrapper')
      if (wrapper) {
        gsap.fromTo(
          '.prg-cta-block-wrapper',
          {
            backgroundPositionY: '0%',
          },
          {
            backgroundPositionY: '100%',
            scrollTrigger: { ...scrollParams, scrub: 2, once: false },
          },
        )
      }

      // Do illustration if it exists
      const svg = document.querySelector('.prg-cta-block-wrapper-svg')
      if (svg) {
        gsap.fromTo(
          '.prg-cta-block-wrapper-svg',
          {
            scale: 0.5,
          },
          {
            scale: 1,
            scrollTrigger: { ...scrollParams, scrub: 2, once: false },
          },
        )
      }
    }, 250)
  }, [isMobile, restaurant.styles])

  const getContent = () => {
    const before =
      heading &&
      heading[0] &&
      heading[0].children &&
      heading[0].children[0].text
        ? heading[0].children[0].text
        : ''
    const headingEl =
      heading && heading[0].children[0].text !== '' ? (
        <h2 data-before={before}>
          <HtmlRendererComponent copy={heading} />
        </h2>
      ) : null

    const imageEl = image && (
      <img src={API.getOptimizedImage(image)} alt={image.altText} />
    )

    const copyEl = copy ? (
      <p>
        <HtmlRendererComponent copy={copy} />
      </p>
    ) : (
      <span className="spacer" />
    )

    const buttonsEl = (
      <div className="prg-cta-block-btns">
        {buttons &&
          buttons.map((b) => {
            const { buttonLabel, buttonLink, buttonStyle } = b
            return (
              <button
                key={b.id}
                className={
                  buttonStyle === 'primary'
                    ? 'prg-primary-btn'
                    : 'prg-primary-btn outline'
                }
                onClick={() =>
                  Utils.handleAction(buttonLink, restaurant, navigate)
                }
              >
                {buttonLabel}
              </button>
            )
          })}
      </div>
    )

    if (brandStyle === 'patterned' || brandStyle === 'illustration') {
      const style = image
        ? { backgroundImage: `url('${API.getMediaAssetUrl(image.url)}')` }
        : {}
      const lightbox =
        brandStyle === 'illustration' && illustration?.useLightbox ? (
          <div className="prg-cta-block-wrapper-lightbox" />
        ) : null

      const svg =
        brandStyle === 'illustration' && illustration?.svg ? (
          <div
            className="prg-cta-block-wrapper-svg"
            dangerouslySetInnerHTML={{ __html: illustration.svg }}
          />
        ) : null

      return (
        <div className="prg-cta-block-wrapper" style={style}>
          {lightbox}
          <div className="prg-cta-block-wrapper-content">
            {svg}
            {headingEl}
            {copyEl}
            {buttonsEl}
          </div>
        </div>
      )
    } else if (brandStyle === 'illustrationWithBorder') {
      const svg = illustration?.svg ? (
        <div
          className="prg-cta-block-wrapper-svg"
          dangerouslySetInnerHTML={{ __html: illustration.svg }}
        />
      ) : null

      const borderEl = illustration.borderSvg ? (
        <div
          className="prg-cta-block-wrapper-svg-border"
          dangerouslySetInnerHTML={{ __html: illustration.borderSvg }}
        />
      ) : null

      return (
        <div className="prg-cta-block-wrapper">
          <div className="prg-cta-block-wrapper-content">
            {borderEl}
            {headingEl}
            {svg}
            {copyEl}
            {buttonsEl}
          </div>
        </div>
      )
    } else if (brandStyle === 'banner') {
      return (
        <div className="prg-cta-block-banner">
          {imageEl}
          <div className="prg-cta-block-banner-meta">
            {restaurant.icons.logoIcon}
            {headingEl}
            {copyEl}
            {buttonsEl}
          </div>
        </div>
      )
    } else {
      return (
        <>
          {headingEl}
          {imageEl}
          {copyEl}
          {buttonsEl}
        </>
      )
    }
  }

  return <div className={`prg-cta-block ${brandStyle}`}>{getContent()}</div>
}

export default CallToActionBlockComponent
