import { useEffect } from 'react'
import API from '../../../api'
import './image-grid.component.css'
import { gsap } from 'gsap'
import useCheckForMobileHook from '../../../hooks/useCheckForMobile'
import Utils from '../../../utils'
import { useNavigate } from 'react-router-dom'

const ImageGridComponent = (props) => {
  const { images, useMasonryLayout, restaurant } = props
  const isMobile = useCheckForMobileHook()
  const navigate = useNavigate()
  const disableAnimations = restaurant.styles.disableImageGridAnimations

  // Animation
  useEffect(() => {
    if (!images || images.length < 1 || isMobile || disableAnimations) return

    setTimeout(() => {
      const scrollParams = {
        once: true,
        trigger: '.prg-image-grid',
        start: 'top 80%',
        end: 'bottom bottom',
        scrub: 1,
      }

      images.forEach((i, index) => {
        const scrub = Math.floor(Math.random() * 3) + 1
        gsap.fromTo(
          `.prg-image-grid-image-${i.id}`,
          {
            yPercent: 50,
          },
          {
            yPercent: 0,
            scrollTrigger: { ...scrollParams, scrub },
          },
        )
      })
    }, 500)
  }, [images, isMobile, disableAnimations])

  const handleClick = (link) => {
    if (link) {
      Utils.handleAction(link, restaurant, navigate)
    }
  }

  return (
    <div
      className={useMasonryLayout ? 'prg-image-grid masonry' : 'prg-image-grid'}
    >
      <ul>
        {images.map((i) => {
          const { id, label, image, link } = i
          return (
            <li
              key={id}
              className={`prg-image-grid-image-${id} ${
                link ? 'actionable' : ''
              }`}
              onClick={() => handleClick(link)}
            >
              <img src={API.getOptimizedImage(image)} alt={image.altText} />
              {label && <div className="prg-image-grid-label">{label}</div>}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ImageGridComponent
