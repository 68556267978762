import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './cart.page.css'
import DropdownComponent from '../../components/dropdown/dropdown.component'
import API from '../../../api'
import { RestaurantContext } from '../../../prg'
import useScrollTop from '../../../hooks/useScrollTop'
import { Helmet } from 'react-helmet'
import ToastComponent from '../../components/toast/toast.component'
import HeaderComponent from '../../components/header/header.component'
import FooterComponent from '../../components/footer/footer.component'
import { LOCAL_STORAGE_CART_KEY_PREFIX } from '../../../constants'
import Utils from '../../../utils'
import { gsap } from 'gsap'
import TextHeroComponent from '../../components/text-hero/text-hero.component'

const CartPage = () => {
  const Restaurant = useContext(RestaurantContext)
  const [cart, setCart] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const didAnimateCartRef = useRef(false)
  const navigate = useNavigate()

  // Fetch cart on load
  useEffect(() => {
    const fetchCart = async (cartId) => {
      try {
        setLoading(true)
        const cartId = window.localStorage.getItem(cartKey)
        console.log('fetching cart...')
        const res = await API.postContent('cart/full', { cartId })

        if (!res.items || !res.totals || !res.url) {
          // Clear cart
          window.localStorage.removeItem(cartKey)
          document.dispatchEvent(
            new CustomEvent('EVENTS.CART_UPDATE', { detail: 0 }),
          )
          return
        }

        setCart({ ...res, cartId })
        document.dispatchEvent(
          new CustomEvent('EVENTS.CART_UPDATE', { detail: res.items.length }),
        )
      } catch (e) {
        // Display empty cart + clear
        window.localStorage.removeItem(cartKey)
        document.dispatchEvent(
          new CustomEvent('EVENTS.CART_UPDATE', { detail: 0 }),
        )
      } finally {
        setLoading(false)

        // Hide loader
        setTimeout(() => {
          document.dispatchEvent(
            new CustomEvent('EVENTS.SHOW_LOADER', { detail: false }),
          )

          if (window.onCaptureReady) {
            setTimeout(() => window.onCaptureReady(200), 4000)
          }
        }, 250)
      }
    }

    const cartKey = `${LOCAL_STORAGE_CART_KEY_PREFIX}${Restaurant.slug}`
    let willFetch = false
    if (
      window.localStorage.getItem(cartKey) !== undefined &&
      window.localStorage.getItem(cartKey) !== null
    ) {
      willFetch = true
    }

    setTimeout(() => {
      if (willFetch) {
        fetchCart(cartKey)
      } else {
        setCart(false)
        setLoading(false)
      }
    }, 250)
  }, [Restaurant])

  const createQuantityOptions = (max) => {
    const options = []
    for (let i = 1; i <= max; i++) {
      options.push({
        key: i,
        label: `Qty: ${i}`,
      })
    }

    return options
  }

  const handleQuantityUpdate = async (itemLineId, qty) => {
    try {
      setLoading(true)
      const body = {
        cartId: cart.cartId,
        itemLineId,
        qty: qty.key,
      }

      const updateRes = await API.postContent('cart/update', body)

      // Update line item + cost
      setCart({
        ...cart,
        totals: updateRes.totals,
        items: [...cart.items].map((i) => {
          if (i.id === itemLineId) {
            return { ...i, quantity: qty.key }
          }

          return i
        }),
      })
    } catch (e) {
      console.error(e)

      document.dispatchEvent(
        new CustomEvent('EVENTS.SHOW_TOAST', {
          detail: {
            style: 'error',
            message:
              'Uh oh. We had an issue updating that product in your cart. Please try again',
          },
        }),
      )
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteItem = async (itemLineId) => {
    try {
      setLoading(true)
      const body = {
        cartId: cart.cartId,
        itemLineId,
      }
      const updateRes = await API.postContent('cart/delete', body)

      // Update line item + cost
      setCart({
        ...cart,
        totals: updateRes.totals,
        items: [...cart.items].filter((i) => i.id !== itemLineId),
      })

      document.dispatchEvent(
        new CustomEvent('EVENTS.CART_UPDATE', {
          detail: cart.items.length - 1,
        }),
      )
    } catch (e) {
      console.error(e)

      document.dispatchEvent(
        new CustomEvent('EVENTS.SHOW_TOAST', {
          detail: {
            style: 'error',
            message:
              'Uh oh. We had an issue deleting that product from your cart. Please try again',
          },
        }),
      )
    } finally {
      setLoading(false)
    }
  }

  const handleProceedCheckout = () => {
    window.location.href = cart.url
  }

  // Animate page
  useLayoutEffect(() => {
    if (cart === undefined || didAnimateCartRef.current) return

    const doAnimations = () => {
      const headingProps = { y: 0, opacity: 1, duration: 0.4 }

      gsap.to('.prg-cart-page-content h2', { ...headingProps, delay: 0.2 })
      gsap.to('.prg-cart-page-content h5', {
        ...headingProps,
        delay: 0.3,
      })

      if (cart?.items?.length > 0) {
        gsap.to('.prg-cart-item', {
          y: 0,
          opacity: 1,
          scale: 1,
          duration: 0.4,
          ease: 'power2.in',
          stagger: 0.05,
        })

        gsap.to('.prg-cart-page-content .prg-subtotal', {
          ...headingProps,
          delay: 0.1,
        })
        gsap.to('.prg-cart-page-content .prg-primary-btn', {
          ...headingProps,
          delay: 0.1,
        })
      }

      didAnimateCartRef.current = true
    }

    setTimeout(doAnimations, 250)
  }, [cart])

  const getItemCountLabel = () => {
    if (loading) {
      return 'Loading cart...'
    }

    if (cart?.items?.length > 0) {
      return `${cart.items.length} ${
        cart.items.length === 1 ? 'item' : 'items'
      }`
    }

    return 'No items yet...'
  }

  const handleGoToShop = (pid) => {
    const path = `/shop?p=${pid}`
    Utils.maybeShowLoader(path)
    navigate(path)
  }

  useScrollTop()

  return (
    <>
      <Helmet>
        <title>{Restaurant.name} - Shopping Cart</title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${window.location.host}/cart`} />
        <link rel="icon" href={`/${Restaurant.slug}/favicon.ico`} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`/${Restaurant.slug}/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`/${Restaurant.slug}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`/${Restaurant.slug}/favicon-16x16.png`}
        />
        <link rel="manifest" href={`/${Restaurant.slug}/site.webmanifest`} />
      </Helmet>
      <ToastComponent />
      <HeaderComponent restaurant={Restaurant} />
      <div className="prg-page-wrapper">
        <div className="prg-page-container">
          <div className="prg-cart-page">
            <div className="prg-cart-page-content">
              <TextHeroComponent
                heading="Cart"
                subHeading={getItemCountLabel()}
              />
              {!loading && (!cart || cart.items?.length < 1) && (
                <div className="prg-cart-no-items">
                  <h4>Your cart is empty - time to start shopping!</h4>
                  <Link
                    className="prg-primary-btn"
                    to="/shop"
                    onClick={() => Utils.maybeShowLoader('/shop')}
                  >
                    Visit Shop
                  </Link>
                </div>
              )}
              {cart && cart.items?.length > 0 && (
                <div className="prg-cart-container">
                  <ul className="prg-cart-items">
                    {cart.items.map((i, index) => {
                      const zIndex = cart.items.length - index
                      const product = i.merchandise.product
                      const maxQty =
                        i.merchandise.quantityAvailable > 9
                          ? 10
                          : i.merchandise.quantityAvailable

                      return (
                        <li
                          key={i.id}
                          className="prg-cart-item"
                          style={{ zIndex }}
                        >
                          <div
                            className="prg-cart-image"
                            onClick={() => handleGoToShop(product.id)}
                          >
                            <img
                              src={product.featuredImage.url}
                              alt={product.title}
                            />
                          </div>
                          <div className="prg-cart-meta">
                            <h4>{product.title}</h4>
                            {product.variants.nodes.length > 1 && (
                              <h6>
                                {i.merchandise.selectedOptions[0].name}:{' '}
                                <strong>
                                  {i.merchandise.selectedOptions[0].value}
                                </strong>
                              </h6>
                            )}
                            <h6
                              className={
                                i.merchandise.quantityAvailable > 0
                                  ? 'prg-in-stock'
                                  : 'prg-no-stock'
                              }
                            >
                              {i.merchandise.quantityAvailable > 0
                                ? 'In Stock'
                                : 'Out of Stock'}
                            </h6>
                            <DropdownComponent
                              disabled={
                                loading || i.merchandise.quantityAvailable < 1
                              }
                              options={createQuantityOptions(maxQty)}
                              value={{
                                key: i.quantity,
                                label: `Qty: ${i.quantity}`,
                              }}
                              onChange={(v) => handleQuantityUpdate(i.id, v)}
                            />
                            <button
                              disabled={loading}
                              className="prg-cart-delete-btn"
                              onClick={() => handleDeleteItem(i.id)}
                            >
                              Delete
                            </button>
                          </div>
                          <div className="prg-cart-price">
                            <h4>
                              {i.merchandise.quantityAvailable < 1
                                ? ''
                                : `$
                              ${parseFloat(i.merchandise.price.amount).toFixed(
                                2,
                              )}`}
                            </h4>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                  <h4
                    className={
                      loading ? 'prg-subtotal loading' : 'prg-subtotal'
                    }
                  >
                    Subtotal:
                    <span>
                      {loading
                        ? ''
                        : `$${parseFloat(
                            cart.totals.subtotalAmount.amount,
                          ).toFixed(2)}`}
                    </span>
                  </h4>
                  <button
                    className="prg-primary-btn"
                    onClick={handleProceedCheckout}
                    disabled={!cart.url || loading}
                  >
                    {loading ? 'Updating...' : 'Proceed to checkout'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <FooterComponent restaurant={Restaurant} />
      </div>
    </>
  )
}

export default CartPage
