import './people-list.component.css'
const PeopleListComponent = (props) => {
  const { heading, subHeading, people } = props

  const handleClickPerson = (e, person) => {
    e.stopPropagation()

    if (!person.bio) return

    // Dispatch event
    document.dispatchEvent(
      new CustomEvent('EVENTS.SHOW_PERSON', { detail: person }),
    )
  }

  return (
    <div className={`prg-people-list`}>
      <div className="prg-people-list-container">
        {heading && <h1>{heading}</h1>}
        {subHeading && <h4>{subHeading}</h4>}
        <div className="prg-people-list-items">
          {people.map((i) => {
            const { id, name, title, bio } = i
            return (
              <div
                key={id}
                className={
                  bio
                    ? 'prg-people-list-item actionable'
                    : 'prg-people-list-item'
                }
                onClick={(e) => handleClickPerson(e, i)}
              >
                {name && <h5>{name}</h5>}
                {title && <h6>{title}</h6>}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default PeopleListComponent
