import './location-selector.component.css'
import { useEffect } from 'react'
import { gsap } from 'gsap'
import useCheckForMobileHook from '../../../hooks/useCheckForMobile'

const LocationSelectorComponent = (props) => {
  const { restaurant } = props
  const isMobile = useCheckForMobileHook()

  // Setup scroll triggers
  useEffect(() => {
    if (isMobile || restaurant.styles.disableParallax) return

    const scrollParams = {
      once: true,
      trigger: '.prg-location-selector',
      start: 'top 95%',
      end: 'bottom 60%',
      scrub: 1,
    }

    setTimeout(() => {
      gsap.fromTo(
        '.prg-location-selector-hours',
        {
          yPercent: 100,
        },
        {
          yPercent: 0,
          scrollTrigger: scrollParams,
        },
      )

      gsap.fromTo(
        '.prg-location-selector-location',
        {
          yPercent: 100,
        },
        {
          opacity: 1,
          yPercent: 0,
          scrollTrigger: { ...scrollParams, start: 'top 90%', scrub: 2 },
        },
      )

      const underlineBtn = document.querySelector('.prg-underline-btn')
      if (underlineBtn) {
        gsap.fromTo(
          '.prg-underline-btn',
          {
            scale: 0,
            yPercent: 200,
          },
          {
            scale: 1,
            yPercent: 0,
            scrollTrigger: { ...scrollParams, start: 'top 85%' },
          },
        )
      }

      gsap.fromTo(
        '.prg-location-selector-right',
        {
          scale: 0.9,
          yPercent: 50,
        },
        {
          yPercent: 0,
          scale: 1,
          scrollTrigger: { ...scrollParams, scrub: 3 },
        },
      )
    }, 250)
  }, [isMobile, restaurant.styles])

  const handleChangeLocation = () => {
    document.dispatchEvent(
      new CustomEvent('EVENTS.TOGGLE_ALL_LOCATIONS', { detail: true }),
    )
  }

  if (!restaurant.selectedLocation) return null

  return (
    <div className="prg-location-selector">
      <div className="prg-location-selector-left">
        <div className="prg-location-selector-hours">
          <h3>{restaurant.styles.locationPickerText || 'Restaurant Hours'}</h3>
          <p>{restaurant.selectedLocation.hours}</p>
        </div>
        <div className="prg-location-selector-location">
          <h3>Location</h3>
          <h4>{restaurant.selectedLocation.name}</h4>
          <p>{restaurant.selectedLocation.address}</p>
          {restaurant.selectedLocation.phoneNumber && (
            <p>{restaurant.selectedLocation.phoneNumber}</p>
          )}
        </div>
        {restaurant.locations?.length > 1 && (
          <button className="prg-underline-btn" onClick={handleChangeLocation}>
            Change Location
          </button>
        )}
      </div>
      <div
        className="prg-location-selector-right"
        dangerouslySetInnerHTML={{
          __html: restaurant.selectedLocation.illustration,
        }}
      />
    </div>
  )
}

export default LocationSelectorComponent
