class Session {
  static read = (key = '_prg-store') => {
    const item = window.localStorage.getItem(key)
    if (item) {
      const json = JSON.parse(item)
      return json
    }

    return false
  }

  static write = (item, key = '_prg-store') => {
    window.localStorage.setItem(key, JSON.stringify(item))
  }

  static clear = (key = '_prg-store') => {
    window.localStorage.removeItem(key)
  }
}

export default Session
