import './text-hero.component.css'

const TextHeroComponent = (props) => {
  const { heading, subHeading, copy, style } = props

  return (
    <div className={`prg-text-hero style-${style}`}>
      <div className="prg-text-hero-container">
        <div className="prg-text-hero-headings">
          <h2>{heading}</h2>
          {subHeading && <h2 className="subheading">{subHeading}</h2>}
        </div>
        {copy && <p>{copy}</p>}
      </div>
    </div>
  )
}

export default TextHeroComponent
