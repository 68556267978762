import { downArrowIcon, upArrowIcon } from './shared/icons'

export const VALID_SUBDOMAINS = [
  'hero',
  'pihakis',
  'rodney-scotts',
  'little-donkey',
  'luca',
  'tasty-town',
  'magnolia-point',
  'psito',
]

export const VALID_PROD_DOMAINS = [
  'eatathero.com',
  'rodneyscottsbbq.com',
  'eatluca.com',
  'eattastytown.com',
  'pihakis.com',
  'thelittledonkey.com',
  'eatmagnoliapoint.com',
  'eatpsito.com',
]

export const PROD_SLUG_MAPS = {
  'eatathero.com': 'hero',
  'rodneyscottsbbq.com': 'rodney-scotts',
  'eatluca.com': 'luca',
  'eattastytown.com': 'tasty-town',
  'pihakis.com': 'pihakis',
  'thelittledonkey.com': 'little-donkey',
  'eatmagnoliapoint.com': 'magnolia-point',
  'eatpsito.com': 'psito',
}

export const SORT_OPTIONS = [
  {
    label: 'Best Selling',
    key: 'BEST_SELLING',
  },
  {
    label: 'Name',
    key: 'ALPHA_ASC',
    icon: upArrowIcon,
  },
  {
    label: 'Name',
    key: 'ALPHA_DESC',
    icon: downArrowIcon,
  },
  {
    label: 'Price',
    key: 'PRICE_ASC',
    icon: upArrowIcon,
  },
  {
    label: 'Price',
    key: 'PRICE_DESC',
    icon: downArrowIcon,
  },
]

export const LOCAL_STORAGE_CART_KEY_PREFIX = '__prg-cart-id-'
export const ASSETS_URL = 'https://djhp50hmxm6q6.cloudfront.net'
export const GIFT_CARD_URL =
  'https://pihakis.train.paytronix.com/en-us/guest/egift?page=cardInfo'
