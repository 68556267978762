import './prg.css'
import { createContext, useEffect, useRef, useState } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Session from './session'
import API from './api'
import Page from './shared/pages/page/page.page'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import PreloaderComponent from './shared/components/preloader/preloader.component'
import Utils from './utils'
import PageTransitionComponent from './shared/components/page-transition/page-transition.component'
import CartPage from './shared/pages/cart/cart.page'
import PromoPage from './shared/pages/promo/promo.page'

const RestaurantContext = createContext()

const Prg = () => {
  const [loadingRestaurant, setLoadingRestaurant] = useState()
  const timerRef = useRef()

  // Register scroll trigger + scroll to
  gsap.registerPlugin(ScrollTrigger)
  gsap.registerPlugin(ScrollToPlugin)

  // Restaurant context will hold restaurant config
  const [restaurant, setRestaurant] = useState()

  // Fetch restaurant based on URL
  useEffect(() => {
    timerRef.current = performance.now()
    const slug = Utils.getSlugFromDomain()

    // Fetch
    const fetchRestaurant = async () => {
      try {
        const res = await API.getContent('restaurants', null, slug)
        console.log('welcome to prg -> ', res)

        // Import css
        import(`./apps/${slug}/${slug}.css`)

        // Set icons
        const icons = await import(`./apps/${slug}/icons`)
        res.icons = icons

        // Set map json
        const map = await import(`./apps/${slug}/map`)
        res.map = map.default

        // Set styles
        const styles = await import(`./apps/${slug}/styles`)
        res.styles = styles.default
        setLoadingRestaurant(res)

        // Add gtm if defined
        if (res.googleTagId) {
          Utils.addGoogleTags(res.googleTagId)
        }

        // Check if session exists
        const session = Session.read()

        // Set selected location if it exists
        let desiredLocation
        if (session && session[slug]) {
          desiredLocation = res.locations.find((l) => l.id === session[slug])
        }

        // Check for geolocation
        const deferred = Utils.createDeferred()
        if (!res.locations || res.locations.length < 2) {
          deferred.resolve()
        } else {
          let geoSession = Session.read('_prg-geo')
          if (!geoSession) {
            if (navigator?.geolocation) {
              navigator.geolocation.getCurrentPosition(
                (pos) => {
                  const { latitude: lat, longitude: long } = pos.coords
                  geoSession = { lat, long }
                  Session.write(JSON.stringify(geoSession), '_prg-geo')
                  deferred.resolve()
                },
                (err) => {
                  console.error('Error fetching location: ', err)
                  deferred.resolve()
                },
              )
            } else {
              deferred.resolve()
            }
          } else {
            geoSession = JSON.parse(geoSession)
            deferred.resolve()
          }

          // Wait for geolocation
          await deferred.promise

          // If we don't have a desired location & have geolocation, let's set the location based on that
          if (!desiredLocation && geoSession) {
            // Find closest location
            const closest = { dist: Infinity }
            res.locations.forEach((l) => {
              const dist = Utils.calculateDistanceBetweenPoints(
                {
                  lat: parseFloat(l.googleMaps.lat),
                  long: parseFloat(l.googleMaps.long),
                },
                geoSession,
              )

              if (dist < closest.dist) {
                closest.dist = dist
                closest.location = l
              }
            })

            if (closest.location) {
              desiredLocation = closest.location
            }
          }
        }

        // All done
        setRestaurant({
          ...res,
          selectedLocation: desiredLocation
            ? desiredLocation
            : res.defaultLocation,
          setRestaurant,
        })

        // Make sure we showed preloader for 1s, if so, hide it
        const diff = performance.now() - timerRef.current
        let delay = 0
        if (diff < 2000) {
          delay = 2000 - diff
        }

        setTimeout(() => {
          // Notify
          document.dispatchEvent(new CustomEvent('EVENTS.HIDE_PRELOADER'))
          setTimeout(() => setLoadingRestaurant(false), 500)
        }, delay)
      } catch (e) {
        console.error(e)
        // todo handle error
      }
    }

    if (slug) {
      fetchRestaurant()
    }
  }, [])

  // Intercept native back buttons and show loader
  useEffect(() => {
    const handleNativeNavEvents = () => {
      document.dispatchEvent(
        new CustomEvent('EVENTS.SHOW_LOADER', { detail: true }),
      )
    }

    window.addEventListener('popstate', handleNativeNavEvents)

    return () => window.removeEventListener('popstate', handleNativeNavEvents)
  }, [])

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Page slug="home" />,
    },
    {
      path: '/index.html',
      element: <Page slug="home" />,
    },
    {
      path: '/cart',
      element: <CartPage />,
    },
    {
      path: '/:slug',
      element: <Page />,
    },
    {
      path: '/promos/:slug',
      element: <PromoPage />,
    },
    {
      path: '/:slug/*',
      element: <Page />,
    },
  ])

  if (loadingRestaurant) {
    return <PreloaderComponent restaurant={loadingRestaurant} />
  }

  if (!restaurant && !loadingRestaurant) {
    return null
  }

  return (
    <RestaurantContext.Provider value={restaurant}>
      <RouterProvider router={router} />
      <PageTransitionComponent
        useExpandedHeader={restaurant.styles?.useExpandedHeader}
        disableHeader={restaurant.styles?.disableHeader}
      />
    </RestaurantContext.Provider>
  )
}

export default Prg
export { RestaurantContext }
