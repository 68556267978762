import './three-piece-content.component.css'

const ThreePieceContentComponent = (props) => {
  const { headingSvg, subHeading, subHeadingSvg, copy } = props
  return (
    <div className="prg-three-piece-content">
      <div className="prg-three-piece-content-container">
        <div
          className="prg-three-piece-heading-svg"
          dangerouslySetInnerHTML={{ __html: headingSvg }}
        />

        <div className="prg-three-piece-content-flex">
          <div className="prg-three-piece-content-flex-subheading">
            <h2>{subHeading}</h2>
            <div
              className="prg-three-piece-content-flex-subheading-svg"
              dangerouslySetInnerHTML={{ __html: subHeadingSvg }}
            ></div>
          </div>
          <div className="prg-three-piece-content-flex-copy">
            <p>{copy}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThreePieceContentComponent
