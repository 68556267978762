import API from '../../../api'
import './press-grid.component.css'
import { Link, useNavigate } from 'react-router-dom'
import Utils from '../../../utils'

const PressGridComponent = (props) => {
  const { restaurant } = props
  const { pressReleases } = restaurant
  const navigate = useNavigate()

  const handlePressClick = (p) => {
    if (p.isExternal && p.externalLink) {
      window.open(p.externalLink, '_blank')
    } else {
      Utils.maybeShowLoader(p.slug)
      navigate(p.slug)
    }
  }

  return (
    <div className="prg-press-grid">
      <div className="prg-press-grid-content">
        {pressReleases.length < 1 && <h6>No press releases just yet!</h6>}
        <div className="prg-press-grid-items">
          {pressReleases.map((p) => {
            return (
              <div className="prg-press-grid-item" key={p.id}>
                <img
                  src={API.getMediaAssetUrl(p.heroImage.url)}
                  alt={p.heroImage.altText}
                />
                <h3>{p.title}</h3>
                <Link
                  onClick={() => handlePressClick(p)}
                  className="prg-primary-btn"
                >
                  {p.readMoreText}
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default PressGridComponent
