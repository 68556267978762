import './call-to-action-button-group.component.css'
import { useNavigate } from 'react-router-dom'
import Utils from '../../../utils'

const CallToActionButtonGroupComponent = (props) => {
  const {
    heading,
    subHeading,
    copy,
    buttons,
    direction,
    imageSvg,
    restaurant,
    disableMargins,
    enableBorder,
  } = props
  const navigate = useNavigate()

  const handleAction = (action) => {
    Utils.handleAction(action, restaurant, navigate)
  }

  const getButtonClassName = (buttonStyle) => {
    switch (buttonStyle) {
      default: {
        return 'prg-primary-btn'
      }
      case 'secondary': {
        return 'prg-primary-btn outline'
      }
      case 'tertiary': {
        return 'prg-primary-btn tertiary'
      }
      case 'quaternary': {
        return 'prg-primary-btn quaternary'
      }
      case 'text': {
        return 'prg-text-btn'
      }
      case 'textSecondary': {
        return 'prg-text-secondary-btn'
      }
    }
  }

  const ulClassName =
    direction === 'vertical'
      ? 'prg-cta-button-group-btns vertical'
      : 'prg-cta-button-group-btns'

  const getClassName = () => {
    const classArr = ['prg-cta-button-group']

    if (disableMargins) {
      classArr.push('disable-margins')
    }

    if (enableBorder) {
      classArr.push('with-border')
    }

    return classArr.join(' ')
  }

  return (
    <div className={getClassName()}>
      <div className="prg-cta-button-group-container">
        {(heading || subHeading) && (
          <div className="prg-cta-button-group-container-headings">
            <h2 data-before={heading}>
              {heading}
              {subHeading && <span className="subheading">{subHeading}</span>}
            </h2>
          </div>
        )}
        {copy && <p>{copy}</p>}
        {imageSvg && (
          <div
            className="prg-cta-button-group-svg"
            dangerouslySetInnerHTML={{ __html: imageSvg }}
          ></div>
        )}
        <ul className={ulClassName}>
          {buttons &&
            buttons.length > 0 &&
            buttons.map((b) => {
              const {
                buttonHeading,
                buttonLabel,
                buttonStyle,
                buttonLink,
                id,
              } = b

              // Intercept reservation buttons w/o reservation links
              if (
                buttonLink === 'action:reservation' &&
                (!restaurant.selectedLocation?.includeReservationLink ||
                  !restaurant.selectedLocation?.reservationLink)
              ) {
                return null
              }

              return (
                <li key={id}>
                  {buttonHeading && <h6>{buttonHeading}</h6>}
                  <button
                    className={getButtonClassName(buttonStyle)}
                    onClick={() => handleAction(buttonLink)}
                  >
                    {buttonLabel}
                  </button>
                </li>
              )
            })}
        </ul>
      </div>
    </div>
  )
}

export default CallToActionButtonGroupComponent
