import { useCallback, useEffect, useState } from 'react'
import API from '../../../api'
import './careers-list.component.css'
import { Link } from 'react-router-dom'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { closeIcon } from '../../icons'

const CareersListComponent = (props) => {
  const { heading } = props
  const [pagination, setPagination] = useState({ page: 0 })
  const [positions, setPositions] = useState([])
  const [loading, setLoading] = useState(true)
  const [showFilters, setShowFilters] = useState(false)
  const [location, setLocation] = useState('all')
  const locationRef = useDetectClickOutside({
    onTriggered: () => setShowFilters(false),
  })

  const fetchItems = useCallback((page, loc) => {
    setLoading(true)

    return new Promise(async (resolve, reject) => {
      try {
        const items = await API.getContent(
          'careers',
          `${page.toString()}/${loc}`,
        )
        resolve(items)
      } catch (e) {
        console.error(e)

        // todo handle
        reject(e)
      } finally {
        setLoading(false)
      }
    })
  }, [])

  const handleFetchMore = async () => {
    if (pagination.page + 1 < pagination.numPages) {
      // Fetch more
      const nextPage = pagination.page + 1
      const moreItems = await fetchItems(nextPage, location)
      setPagination({ ...pagination, page: nextPage })
      setPositions([...positions, ...moreItems.list])
    }
  }

  // Switch location
  const handleSelectLocation = useCallback(
    async (loc) => {
      setLocation(loc)
      setShowFilters(false)

      // Reset pagination and fetch
      const filteredItems = await fetchItems(0, loc)
      const paginationParams = {
        page: 0,
        numPages: Math.ceil(filteredItems.total / filteredItems.numRequested),
      }

      setPagination(paginationParams)
      setPositions([...filteredItems.list])
    },
    [fetchItems],
  )

  const handleClearLocation = () => {
    setLocation(false)

    // Reset
    doInitialFetch()
  }

  const doInitialFetch = useCallback(async () => {
    // Check for location
    const urlParams = new URLSearchParams(window.location.search)
    const loc = urlParams.get('s') || 'all'
    const items = await fetchItems(0, loc)
    const paginationParams = {
      page: 0,
      numPages: Math.ceil(items.total / items.numRequested),
    }

    setPagination(paginationParams)
    setPositions(items.list)

    if (loc !== 'all') {
      setLocation(loc)
    }
  }, [fetchItems])

  // Watch for filter event
  useEffect(() => {
    const handleFilterEvent = (e) => {
      handleSelectLocation(e.detail)
    }

    document.addEventListener('EVENTS.FILTER_CAREERS', handleFilterEvent)

    return () =>
      document.removeEventListener('EVENTS.FILTER_CAREERS', handleFilterEvent)
  }, [handleSelectLocation])

  // Fetch items on load
  useEffect(() => {
    doInitialFetch()
  }, [doInitialFetch])

  return (
    <div className="prg-careers-list">
      <div className="prg-careers-list-content">
        <h1>{heading}</h1>
        {!loading && positions.length < 1 && (
          <h6 className="no-items">No open positions at this time!</h6>
        )}
        {loading && positions.length < 1 && (
          <h6 className="no-items">Checking for open positions...</h6>
        )}
        {!loading && positions.length > 0 && (
          <div className="prg-careers-list-filters" ref={locationRef}>
            {showFilters && (
              <ul className="prg-careers-list-location-filters">
                <li>
                  <button onClick={() => handleSelectLocation('Georgia')}>
                    Georgia
                  </button>
                </li>
                <li>
                  <button onClick={() => handleSelectLocation('Alabama')}>
                    Alabama
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleSelectLocation('South Carolina')}
                  >
                    South Carolina
                  </button>
                </li>
                <li>
                  <button onClick={() => handleSelectLocation('Tennessee')}>
                    TENNESSEE
                  </button>
                </li>
              </ul>
            )}
            {location && location !== 'all' && (
              <button
                className="prg-primary-btn text selected-location-btn"
                onClick={handleClearLocation}
              >
                {location} {closeIcon}
              </button>
            )}
            <button
              className="prg-primary-btn text filter-btn"
              onClick={() => setShowFilters(true)}
            >
              Filter
            </button>
          </div>
        )}
        <div className="prg-careers-list-items">
          {positions.map((p) => {
            return (
              <div
                className="prg-careers-list-item"
                key={p.applicationURL + p.addressLine1}
              >
                <Link to={p.applicationURL} target="_blank">
                  <h5>{p.jobTitle}</h5>
                </Link>
                <h6>
                  {p.city}, {p.stateProvCode}
                </h6>
              </div>
            )
          })}
          {pagination.page + 1 < pagination.numPages && (
            <button
              className="prg-primary-btn"
              disabled={loading}
              onClick={handleFetchMore}
            >
              {loading ? 'Loading more...' : 'See more'}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default CareersListComponent
