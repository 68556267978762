import API from '../../../api'
import HtmlRendererComponent from '../html-renderer/html-renderer.component'
import './person-modal.component.css'
import { useDetectClickOutside } from 'react-detect-click-outside'

const PersonModalComponent = (props) => {
  const { person, onClose } = props
  const refEl = useDetectClickOutside({ onTriggered: onClose })

  return (
    <div className="prg-person-modal">
      <div className="prg-person-modal-content" ref={refEl}>
        <div className="prg-person-modal-header">
          <button className="prg-primary-btn text" onClick={onClose}>
            Close
          </button>
        </div>
        <div className="prg-person-modal-flex">
          {person.photo?.url && (
            <div className="prg-person-modal-media">
              <img
                src={API.getMediaAssetUrl(person.photo.url)}
                alt={person.photo.altText}
              />
            </div>
          )}
          <div
            className={
              !person.photo
                ? 'prg-person-modal-meta full-width'
                : 'prg-person-modal-meta'
            }
          >
            <h1>{person.name}</h1>
            <h6>{person.title}</h6>
            <div className="prg-person-modal-meta-bio">
              <HtmlRendererComponent copy={person.bio} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonModalComponent
