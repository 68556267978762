import { useNavigate } from 'react-router-dom'
import API from '../../../api'
import './flex-promo.component.css'
import Utils from '../../../utils'

const FlexPromoComponent = (props) => {
  const {
    heading,
    style,
    enableDividers,
    useSvgDivider,
    svgDivider,
    promos,
    restaurant,
  } = props
  const navigate = useNavigate()

  const handleAction = (action) => {
    Utils.handleAction(action, restaurant, navigate)
  }

  const getClassName = () => {
    if (enableDividers) {
      return !useSvgDivider
        ? 'prg-flex-promo-container with-dividers'
        : 'prg-flex-promo-container min-height'
    }

    return 'prg-flex-promo-container'
  }

  return (
    <div className={`prg-flex-promo style-${style}`}>
      {heading && <h3 className="prg-flex-promo-heading">{heading}</h3>}
      <div className={getClassName()}>
        {enableDividers && useSvgDivider && svgDivider && (
          <div
            className="prg-flex-promo-svg-divider"
            dangerouslySetInnerHTML={{ __html: svgDivider }}
          />
        )}
        {promos.map((p, index) => {
          const {
            copy,
            heading,
            subHeading,
            image,
            imageSvg,
            setSvgHeight,
            justifyContent,
            includeCta,
            button,
            includeAdditionalLinks,
            additionalLinks,
            absolutelyPositionCopy,
          } = p
          const { buttonLabel, buttonStyle, buttonLink } = button
          const svgStyle = {}

          if (imageSvg && setSvgHeight) {
            svgStyle.height = `${setSvgHeight}rem`
          }

          const itemStyle = {}
          if (justifyContent && justifyContent !== 'none') {
            itemStyle.justifyContent = justifyContent
          }

          return (
            <div className="prg-flex-promo-item" key={p.id} style={itemStyle}>
              {heading && <h3>{heading}</h3>}
              {subHeading && !image && !imageSvg && <h4>{subHeading}</h4>}
              {image && !imageSvg && (
                <img
                  src={API.getMediaAssetUrl(image.url)}
                  alt={image.altText}
                />
              )}
              {imageSvg && !image && (
                <div
                  className="prg-flex-promo-svg"
                  style={svgStyle}
                  dangerouslySetInnerHTML={{ __html: imageSvg }}
                />
              )}
              {copy && (
                <p
                  className={
                    absolutelyPositionCopy
                      ? 'abs'
                      : !heading
                      ? 'margin-auto'
                      : ''
                  }
                >
                  {copy}
                </p>
              )}
              {includeCta && (
                <button
                  onClick={() => handleAction(buttonLink)}
                  className={
                    buttonStyle === 'primary'
                      ? 'prg-primary-btn'
                      : 'prg-primary-btn outline'
                  }
                >
                  {buttonLabel}
                </button>
              )}
              {includeAdditionalLinks && additionalLinks?.length > 0 && (
                <ul className="prg-flex-promo-addtl-links">
                  {additionalLinks.map((l) => {
                    const { buttonLabel, buttonStyle, buttonLink } = l
                    const linkStyle =
                      buttonStyle === 'primary'
                        ? 'prg-primary-btn'
                        : 'prg-primary-btn outline'
                    return (
                      <li key={l.id}>
                        <button
                          className={linkStyle}
                          onClick={() => handleAction(buttonLink)}
                        >
                          {buttonLabel}
                        </button>
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default FlexPromoComponent
