import { Link } from 'react-router-dom'
import Utils from '../../../utils'
import './footer.component.css'
import { gsap } from 'gsap'
import { GIFT_CARD_URL } from '../../../constants'

const FooterComponent = (props) => {
  const { restaurant } = props
  const getContentForItem = (item) => {
    switch (item.footerItem) {
      default: {
        console.error('footer item content block not found:', item.footerItem)
        break
      }
      case 'logo': {
        return (
          <Link className="prg-footer-logo" to="/">
            {restaurant.icons.footerLogoIcon
              ? restaurant.icons.footerLogoIcon
              : restaurant.icons.logoIcon}
          </Link>
        )
      }
      case 'copyrightInfo': {
        return <div className="prg-footer-copyright">{item.copyrightInfo}</div>
      }
      case 'callToAction': {
        const page = window.location.pathname.replace('/', '')
        let className = 'prg-primary-btn'
        if (item.buttonStyle === 'outline') {
          className += ' outline'
        }

        // Grab our heading (based on page if defined)
        let heading = item.heading
        let buttonLabel = item.buttonLabel
        let buttonLink = item.buttonLink
        if (
          item.changeButtonOnPage &&
          item.changeProperties &&
          item.changeProperties.updateOnPage === page
        ) {
          heading = item.changeProperties.headingTwo
          buttonLabel = item.changeProperties.buttonLabelTwo
          buttonLink = item.changeProperties.buttonLinkTwo
        }

        if (buttonLink === 'action:giftcards') {
          buttonLink = GIFT_CARD_URL
        }

        return (
          <div className="prg-footer-container-cta">
            <h2>{heading}</h2>
            <Link
              className={className}
              to={buttonLink}
              onClick={() => Utils.maybeShowLoader(buttonLink)}
            >
              {buttonLabel}
            </Link>
          </div>
        )
      }
      case 'socialLinks': {
        return (
          <div className="prg-footer-container-social">
            <h2>{item.heading}</h2>
            <ul>
              {item.socialLinks.map((s) => {
                return (
                  <li key={s.id}>
                    <a target="_blank" rel="noreferrer" href={s.link}>
                      {Utils.getIconFromName(s.icon)}
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        )
      }
      case 'linkGroup': {
        return (
          <ul className="prg-footer-container-link-group">
            {item.linkGroup.map((l) => {
              return (
                <li key={l.id}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={l.link}
                    className="prg-primary-btn"
                  >
                    {l.label}
                  </a>
                </li>
              )
            })}
          </ul>
        )
      }
    }
  }

  const handleScrollTop = () => {
    gsap.to('html', {
      scrollTo: 0,
      duration: 3,
      ease: 'Power2.easeInOut',
    })
  }

  return (
    <footer className="prg-footer">
      <div className="prg-footer-container">
        {restaurant.footerItems.map((f) => {
          return f.footerItem === 'backToTopButton' ? (
            <button
              key={f.id}
              onClick={handleScrollTop}
              className="prg-footer-back-to-top prg-primary-btn"
            >
              {f.backToTopButtonLabel}
            </button>
          ) : (
            <div className="prg-footer-container-item" key={f.id}>
              {getContentForItem(f)}
            </div>
          )
        })}
      </div>
    </footer>
  )
}

export default FooterComponent
