import { useState } from 'react'
import { closeIcon } from '../../icons'
import DropdownComponent from '../dropdown/dropdown.component'
import './order-prompt.component.css'
import Session from '../../../session'

const OrderPromptComponent = (props) => {
  const { onClose, restaurant } = props
  const [displayedSelected, setDisplayedSelected] = useState({
    key: restaurant?.selectedLocation?.id,
    label: `${restaurant?.selectedLocation?.name} - ${restaurant.selectedLocation.address}`,
    phoneNumber: restaurant?.selectedLocation?.phoneNumber,
  })

  const handleLocationChange = (l) => {
    setDisplayedSelected(l)
  }

  const handleContinue = () => {
    // Save location selection
    const selectedLocation = restaurant.locations.find(
      (l) => l.id === displayedSelected.key,
    )
    restaurant.setRestaurant({ ...restaurant, selectedLocation })

    // Write to session
    const session = Session.read()
    const val = session ? session : {}
    val[restaurant.slug] = selectedLocation.id
    Session.write(val)

    // Open up url
    window.open(selectedLocation.orderOnlineUrl, '_blank')

    // Close
    onClose()
  }

  return (
    <div className="prg-order-prompt-container">
      <div className="prg-order-prompt">
        <button className="prg-order-prompt-close-btn" onClick={onClose}>
          {closeIcon}
        </button>
        <h3>Confirm Order Location</h3>
        <h5>
          {restaurant?.locations.length > 1
            ? 'Your selected location:'
            : 'Location:'}
        </h5>
        <DropdownComponent
          value={displayedSelected}
          placeholder={`Select a location`}
          disabled={restaurant?.locations.length < 2}
          options={restaurant?.locations
            ?.sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((l) => ({
              key: l.id,
              label: `${l.name} - ${l.address}`,
              phoneNumber: l.phoneNumber,
            }))}
          onChange={handleLocationChange}
        />
        <h4>
          <span>*</span> Note for larger orders
        </h4>
        <p>
          For day of orders of <strong>$250</strong> or more, please call the
          restaurant
          {displayedSelected?.phoneNumber
            ? ` at ${displayedSelected.phoneNumber} `
            : ' '}
          to place your order.
        </p>
        {restaurant.cateringOrderingUrl && (
          <p>
            For future orders & orders of <strong>$250</strong> or more please
            visit our catering inquiry page{' '}
            <a
              href={restaurant.cateringOrderingUrl}
              target="_blank"
              rel="noreferrer"
            >
              here.
            </a>
          </p>
        )}
        <div className="prg-order-prompt-actions">
          <button className="prg-text-btn" onClick={onClose}>
            Cancel
          </button>
          <button className="prg-primary-btn" onClick={handleContinue}>
            Continue
          </button>
        </div>
      </div>
    </div>
  )
}

export default OrderPromptComponent
