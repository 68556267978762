import CallToActionButtonGroupComponent from '../call-to-action-button-group/call-to-action-button-group.component'
import './block-container.component.css'
import ImageBlockComponent from '../image-block/image-block.component'
import ScrollerContentComponent from '../scroller-content/scroller-content.component'
import { useCallback, useEffect, useState } from 'react'
import HorizontalListComponent from '../horizontal-list/horizontal-list.component'
import TextHeroComponent from '../text-hero/text-hero.component'

const BlockContainerComponent = (props) => {
  const { blocks, blockType, sizing, includeBackgroundColor, restaurant } =
    props
  const [tabIndex, setTabIndex] = useState(0)
  const [content, setContent] = useState()

  const getContentBlock = useCallback(
    (block) => {
      switch (block.blockType) {
        default: {
          console.error('Unsupported content block found: ', block.blockType)
          return null
        }
        case 'ImageBlock': {
          return <ImageBlockComponent key={block.id} {...block} />
        }
        case 'InnerBlockContainer': {
          return (
            <BlockContainerComponent
              key={block.id}
              {...block}
              restaurant={restaurant}
            />
          )
        }
        case 'BlockContainer': {
          return (
            <BlockContainerComponent
              key={block.id}
              {...block}
              restaurant={restaurant}
            />
          )
        }
        case 'CallToActionButtonGroup': {
          return (
            <CallToActionButtonGroupComponent
              key={block.id}
              {...block}
              restaurant={restaurant}
            />
          )
        }
        case 'ScrollerContent': {
          return (
            <ScrollerContentComponent
              key={block.id}
              {...block}
              restaurant={restaurant}
            />
          )
        }
        case 'HorizontalList': {
          return (
            <HorizontalListComponent
              key={block.id}
              {...block}
              restaurant={restaurant}
            />
          )
        }
        case 'TextHero': {
          return (
            <TextHeroComponent
              key={block.id}
              {...block}
              restaurant={restaurant}
            />
          )
        }
      }
    },
    [restaurant],
  )

  // Use tabs potentially based on sizing param
  useEffect(() => {
    if (sizing === 'tabs') {
      setContent(
        <div className="prg-block-item">{getContentBlock(blocks[0])}</div>,
      )
    } else {
      setContent(
        blocks.map((b, i) => {
          let width = 100
          if (blockType === 'BlockContainer') {
            if (sizing === 'equalSizing') {
              width = blocks.length > 1 ? 50 : 70
            } else if (sizing === 'fullWidth') {
              width = 100
            } else if (sizing === 'fortySixtySizing') {
              width = i % 2 === 0 ? 40 : 60
            } else {
              width = i % 2 === 0 ? 45 : 55
            }
          }

          return (
            <div
              className="prg-block-item"
              style={{ width: `${width}%` }}
              key={b.id}
            >
              {getContentBlock(b)}
            </div>
          )
        }),
      )
    }
  }, [blocks, sizing, blockType, getContentBlock])

  const handleSelectTab = (index) => {
    if (index === tabIndex) return
    setTabIndex(index)
    setContent(
      <div className="prg-block-item">{getContentBlock(blocks[index])}</div>,
    )
  }

  const handleGenerateTabMenu = () => {
    return (
      <div className="prg-block-menu-container">
        <ul className="prg-block-menu">
          {blocks.map((b, i) => {
            return (
              <li key={b.id}>
                <button
                  className={tabIndex === i ? 'selected' : ''}
                  onClick={() => handleSelectTab(i)}
                >
                  {b.heading}
                </button>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  const getContainerClass = () => {
    if (blockType === 'BlockContainer') {
      if (sizing === 'fullWidth') {
        return 'prg-block-container-content full-width'
      }

      return 'prg-block-container-content flex'
    }

    return 'prg-block-container-content'
  }

  return (
    <div
      className={
        includeBackgroundColor
          ? 'prg-block-container with-background'
          : 'prg-block-container'
      }
    >
      {sizing === 'tabs' && handleGenerateTabMenu()}
      <div className={getContainerClass()}>{content}</div>
    </div>
  )
}

export default BlockContainerComponent
