import { useEffect, useRef, useState } from 'react'
import './location-map-image-block.component.css'
import { gsap } from 'gsap'
import useCheckForMobileHook from '../../../hooks/useCheckForMobile'
import API from '../../../api'
import maplibregl from '!maplibre-gl' // eslint-disable-line
import 'maplibre-gl/dist/maplibre-gl.css'
import { createRoot } from 'react-dom/client'

const Marker = ({ feature }) => {
  const { title, description, markerImage } = feature.properties
  const [showAddress, setShowAddress] = useState()

  return (
    <div
      className="prg-map-marker"
      onClick={() => setShowAddress(!showAddress)}
      style={{ backgroundImage: API.getOptimizedImage(markerImage) }}
    >
      {showAddress && (
        <div className="prg-map-marker-popup">
          <h6>{title}</h6>
          <p>{description}</p>
        </div>
      )}
      <img src={API.getOptimizedImage(markerImage)} alt={description} />
    </div>
  )
}

const LocationMapImageBlockComponent = (props) => {
  const { restaurant, disableFullBleed } = props
  const mapRef = useRef()
  const mapContainerRef = useRef()
  const markerRef = useRef()
  const isMobile = useCheckForMobileHook()
  const className = disableFullBleed
    ? 'prg-location-map-image-block-container'
    : 'prg-location-map-image-block-full'

  // Animation
  useEffect(() => {
    if (isMobile || restaurant.styles.disableParallax) return

    const scrollParams = {
      once: true,
      trigger: '.prg-location-map-image-block',
      start: 'top 50%',
      end: 'bottom 50%',
      scrub: 1,
    }

    setTimeout(() => {
      gsap.fromTo(
        '.prg-location-map-image-block #prg-location-map-display',
        {
          yPercent: 10,
          opacity: 0,
        },
        {
          yPercent: 0,
          opacity: 1,
          scrollTrigger: scrollParams,
        },
      )
    }, 250)
  }, [isMobile, restaurant.styles])

  const markerClicked = (title) => {
    window.alert(title)
  }

  // Create map on load
  useEffect(() => {
    const createMap = async () => {
      const { selectedLocation, googleMaps } = restaurant
      const { markerImage } = googleMaps

      if (!selectedLocation || !selectedLocation.googleMaps || mapRef.current)
        return

      // Get coords
      const { lat, long } = selectedLocation.googleMaps

      // Create map
      mapRef.current = new maplibregl.Map({
        container: 'prg-location-map-display',
        style: restaurant.map,
        center: [long, lat],
        zoom: 16,
      })

      // Create marker
      markerRef.current = document.createElement('div')
      const feature = {
        type: 'Feature',
        properties: {
          title: `${selectedLocation.restaurantName} - ${selectedLocation.name}`,
          description: selectedLocation.address,
          markerImage,
        },
        geometry: {
          coordinates: [lat, long],
          type: 'Point',
        },
      }

      // Render a Marker Component on our new DOM node
      createRoot(markerRef.current).render(
        <Marker onClick={markerClicked} feature={feature} />,
      )

      // Create a Mapbox Marker at our new DOM node
      new maplibregl.Marker({ element: markerRef.current })
        .setLngLat([long, lat])
        .addTo(mapRef.current)

      mapRef.current.scrollZoom.disable()
    }

    createMap()

    return () => {
      if (mapRef.current) {
        mapRef.current.remove()
        mapRef.current = null
      }
    }
  }, [restaurant])

  return (
    <div className="prg-location-map-image-block">
      <div className={className}>
        <div id="prg-location-map-display" ref={mapContainerRef} />
      </div>
    </div>
  )
}

export default LocationMapImageBlockComponent
