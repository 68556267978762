import { useState } from 'react'
import './dropdown.component.css'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { chevronDownIcon } from '../../icons'

const DropdownComponent = (props) => {
  const { placeholder, value, options, onChange, disabled } = props
  const [expanded, setExpanded] = useState()
  const ref = useDetectClickOutside({ onTriggered: () => setExpanded(false) })

  const handleItemClick = (option, e) => {
    e.stopPropagation()
    if (option.disabled) return

    setExpanded(false)
    onChange(option)
  }

  return (
    <div
      className={
        disabled ? 'prg-dropdown-component disabled' : 'prg-dropdown-component'
      }
      ref={ref}
    >
      <button onClick={() => setExpanded(true)}>
        <p>{value?.label || placeholder}</p>
        {chevronDownIcon}
      </button>
      {expanded && (
        <ul className="prg-dropdown-component-expanded">
          {options.map((o) => {
            return (
              <li
                key={o.key}
                className={o.disabled ? 'disabled' : ''}
                onClick={(e) => handleItemClick(o, e)}
              >
                {o.label} {o.disabled && ` (${o.disabledMessage})`}
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default DropdownComponent
