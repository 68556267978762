import { useContext, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import API from '../../../api'
import { RestaurantContext } from '../../../prg'
import Utils from '../../../utils'
import './promo.page.css'
import { Helmet } from 'react-helmet'
import useScrollTop from '../../../hooks/useScrollTop'
import HtmlRendererComponent from '../../components/html-renderer/html-renderer.component'

const PromoPage = (props) => {
  const { slug: slugFromProps } = props
  const Restaurant = useContext(RestaurantContext)
  const [pageContent, setPageContent] = useState()
  const [notFound, setNotFound] = useState()
  const [loading, setLoading] = useState(true)
  const timerRef = useRef()

  // Get slug & load page via API
  const { slug: slugFromParams } = useParams()
  const slug = slugFromProps || slugFromParams

  // Fetch page content
  useEffect(() => {
    if (!slug) return

    const fetchPageContent = async () => {
      try {
        console.log('fetching promo page: ', slug)
        setLoading(true)
        setNotFound(false)

        // Get id of slug in restaurant promos array
        const id = Utils.getIdFromPromoSlug(Restaurant, slug)
        if (id) {
          const res = await API.getContent('promos', id)
          console.log(res)
          setPageContent(res)
        } else {
          console.log('promo page not found...')
          setNotFound(true)
        }
      } catch (e) {
        console.log('promo page not found...')
        console.error(e)
        setNotFound(true)
      } finally {
        setLoading(false)
        setTimeout(() => {
          document.dispatchEvent(
            new CustomEvent('EVENTS.SHOW_LOADER', { detail: false }),
          )
        }, 250)
      }
    }

    fetchPageContent()

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }
    }
  }, [slug, Restaurant])

  useScrollTop()

  return (
    <>
      <Helmet>
        <title>
          {pageContent?.title ? pageContent.title : Restaurant.name}
        </title>
        <meta property="og:description" content={pageContent?.description} />
        <meta property="description" content={pageContent?.title} />
        <meta property="og:site_name" content={Restaurant.name} />
        <meta property="og:title" content={pageContent?.title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`${window.location.host}/promos/${pageContent?.slug}`}
        />
        <link rel="icon" href={`/${Restaurant.slug}/favicon.ico`} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`/${Restaurant.slug}/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`/${Restaurant.slug}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`/${Restaurant.slug}/favicon-16x16.png`}
        />
        <link rel="manifest" href={`/${Restaurant.slug}/site.webmanifest`} />
        {!loading && notFound && (
          <meta name="prerender-status-code" content="404" />
        )}
      </Helmet>
      <div
        className="prg-page-wrapper prg-promo-page"
        id={`promo-page-${slug}`}
      >
        <div className="prg-promo-restaurant-logo">
          {Restaurant.icons.logoIcon}
        </div>
        {!loading && notFound && (
          <div className="prg-error">
            <h3>Uh oh. We looked everywhere but couldn't find that promo.</h3>
            <p>
              Please try again or go back to our{' '}
              <Link to="/" onClick={() => Utils.maybeShowLoader('/')}>
                home page
              </Link>
            </p>
          </div>
        )}
        {!loading && !notFound && pageContent && (
          <div className="prg-promo-content">
            <h2>{pageContent.title}</h2>
            {pageContent.description && (
              <div className="promo-desc">
                <HtmlRendererComponent copy={pageContent.description} />
              </div>
            )}
            <div className="promo-code-container">
              <div className="promo-code-content">
                <h1>{pageContent.promoCopy}</h1>
                {pageContent.promoImage && (
                  <img
                    src={pageContent.promoImage.url}
                    alt={pageContent.promoImage.alt}
                  />
                )}
                <h4>
                  Code: <span>{pageContent.promoCode}</span>
                </h4>
              </div>
            </div>
            <Link to="/" className="prg-primary-btn">
              Go back Home
            </Link>
          </div>
        )}
      </div>
    </>
  )
}

export default PromoPage
