import './svg-block.component.css'

const SvgBlockComponent = (props) => {
  const { svg, useSvgWidth } = props

  return (
    <div
      className={
        useSvgWidth
          ? 'prg-svg-image-block use-natural-width'
          : 'prg-svg-image-block'
      }
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  )
}

export default SvgBlockComponent
