import './horizontal-list.component.css'

const HorizontalListComponent = (props) => {
  const { items, heading } = props

  return (
    <div className="prg-horizontal-list">
      {heading && <h5>{heading}</h5>}
      <ul>
        {items?.length > 0 &&
          items.map((i) => {
            const { id, name, includeNote, note } = i
            return (
              <li key={id}>
                {includeNote && note?.heading && (
                  <h6>
                    {note.heading}
                    <div dangerouslySetInnerHTML={{ __html: note.svg }}></div>
                  </h6>
                )}
                {name}
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default HorizontalListComponent
