import './catering-info.component.css'

const CateringInfoComponent = (props) => {
  const { restaurant } = props

  if (
    !restaurant.selectedLocation ||
    (!restaurant.selectedLocation.cateringEmail &&
      !restaurant.selectedLocation.cateringPhoneNumber)
  ) {
    return null
  }

  return (
    <div className="prg-catering-info">
      <div className="prg-catering-info-content">
        {restaurant.selectedLocation.cateringEmail && (
          <a
            className="prg-primary-btn"
            href={`mailto:${restaurant.selectedLocation.cateringEmail}`}
          >
            Request via Email
          </a>
        )}
        {restaurant.selectedLocation.cateringPhoneNumber && (
          <p>
            Please call our location at{' '}
            <strong>{restaurant.selectedLocation.cateringPhoneNumber}</strong>{' '}
            for more information
          </p>
        )}
      </div>
    </div>
  )
}

export default CateringInfoComponent
