import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import './shop-items-grid.component.css'
import API from '../../../api'
import { SORT_OPTIONS } from '../../../constants'
import { sortIcon } from '../../icons'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { gsap } from 'gsap'

const ShopItemsGridComponent = (props) => {
  const { collectionName } = props
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState(undefined)
  const [sort, setSort] = useState()
  const [showSort, setShowSort] = useState()
  const didAnimateRef = useRef(false)

  const getItems = useCallback((collection, sortKey) => {
    return new Promise(async (resolve) => {
      try {
        const body = {
          collection,
          sort: sortKey,
        }

        const res = await API.postContent('shop', body)
        setProducts(res)
        resolve()
      } catch (e) {
        // todo: Show error
        setProducts([])
        resolve()
      } finally {
        setLoading(false)
      }
    })
  }, [])

  const handleUpdateSort = (inputSort) => {
    setSort(inputSort)
    getItems(collectionName, inputSort.key)
  }

  const handleSelectItem = useCallback(
    (i, e) => {
      e?.stopPropagation()
      const variants = []

      if (i.variants?.edges?.length > 0) {
        i.variants.edges.forEach((e) => {
          const { title, price, selectedOptions, availableForSale, id } = e.node
          variants.push({
            id,
            key: id,
            label: `${title}  - $${parseFloat(price.amount).toFixed(2)}`,
            selectedOptions,
            disabled: !availableForSale,
            disabledMessage: 'Out of Stock',
            price,
          })
        })
      }

      const images =
        i.images?.edges?.length > 0
          ? i.images.edges.map((i) => i.node.url)
          : [i.featuredImage.url]
      const selectedVariant = variants.length === 1 ? variants[0] : null
      document.dispatchEvent(
        new CustomEvent('EVENTS.SHOW_SHOP_PRODUCT', {
          detail: {
            product: { ...i, variants, selectedVariant, images },
            collectionName,
          },
        }),
      )
    },
    [collectionName],
  )

  const handleShowSort = (e) => {
    e.stopPropagation()
    setShowSort(true)
  }

  const handleCloseSort = () => {
    setShowSort(false)
  }

  const sortRefEl = useDetectClickOutside({
    onTriggered: handleCloseSort,
  })

  // Fetch items on load
  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true)
      await getItems(collectionName, SORT_OPTIONS[0].key)
      setSort(SORT_OPTIONS[0])
    }

    fetchItems()
  }, [getItems, collectionName])

  // Animate items
  useLayoutEffect(() => {
    if (products === undefined || didAnimateRef.current) return

    const doAnimations = () => {
      gsap.to('.prg-shop-item', {
        opacity: 1,
        y: 0,
        stagger: 0.05,
        scale: 1,
        duration: 0.5,
        ease: 'power2.out',
      })

      didAnimateRef.current = true
    }

    setTimeout(doAnimations, 400)
  }, [products])

  // Maybe show pdp if we see a param in url
  useEffect(() => {
    if (products === undefined) return

    const params = new URLSearchParams(window.location.search)
    if (params.get('p') !== undefined) {
      const theProduct = products.find((p) => p.id === params.get('p'))
      if (theProduct) {
        handleSelectItem(theProduct, null)
      }
    }
  }, [products, handleSelectItem])

  return (
    <>
      <div className="prg-shop-items-grid">
        <div className="prg-shop-items-grid-container">
          {products?.length > 4 && (
            <div className="prg-shop-sort">
              <button className="prg-sort-btn" onClick={handleShowSort}>
                {sortIcon}
              </button>
              {showSort && (
                <div className="prg-sort-options" ref={sortRefEl}>
                  <ul className="prg-sort-options-container">
                    {SORT_OPTIONS.map((s) => {
                      return (
                        <li
                          key={s.key}
                          onClick={() => handleUpdateSort(s)}
                          className={sort.key === s.key ? 'selected' : ''}
                        >
                          <span /> {s.label} {s.icon && s.icon}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
            </div>
          )}
          <div className="prg-shop-section">
            {!loading &&
              products?.map((i) => {
                return (
                  <div
                    key={i.id}
                    className="prg-shop-item"
                    onClick={(e) => handleSelectItem(i, e)}
                  >
                    <div className="prg-shop-item-image">
                      <img src={i.featuredImage.url} alt={i.title} />
                    </div>
                    <div className="prg-shop-item-meta">
                      <h5>{i.title}</h5>
                      <h6>
                        $
                        {parseFloat(
                          i.variants.edges[0].node.price.amount,
                        ).toFixed(2)}
                      </h6>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}

export default ShopItemsGridComponent
