import { useContext, useEffect, useRef, useState } from 'react'
import { RestaurantContext } from '../../../prg'
import './header.component.css'
import HeaderMenuComponent from '../header-menu/header-menu.component'
import {
  cartIcon,
  chevronDownIcon,
  chevronUpIcon,
  closeIcon,
  hamburgerIcon,
} from '../../icons'
import { Link, useLocation } from 'react-router-dom'
import Utils from '../../../utils'
import { gsap } from 'gsap'
import useCheckForMobileHook from '../../../hooks/useCheckForMobile'
import LocationHeaderComponent from '../location-header/location-header.component'
import HtmlRendererComponent from '../html-renderer/html-renderer.component'

const HeaderComponent = () => {
  const restaurant = useContext(RestaurantContext)
  const [showMenu, setShowMenu] = useState()
  const [showLogo, setShowLogo] = useState()
  const [showAllLocations, setShowAllLocations] = useState()
  const showLogoRef = useRef()
  const logoVisibleRef = useRef()
  const hasScrolledPastRef = useRef()
  const location = useLocation()
  const mobile = useCheckForMobileHook()
  const [itemCount, setItemCount] = useState(0)

  // Listen for cart item count
  useEffect(() => {
    const updateItemCount = (e) => {
      setItemCount(e.detail)
    }

    document.addEventListener('EVENTS.CART_UPDATE', updateItemCount)
    return () =>
      document.removeEventListener('EVENTS.CART_UPDATE', updateItemCount)
  }, [])

  // Set logo state
  useEffect(() => {
    const willShowLogo = location.pathname !== '/'
    showLogoRef.current = willShowLogo
    setShowLogo(willShowLogo)
  }, [location])

  // Observe scroll position for logo on home page
  useEffect(() => {
    const handleScroll = () => {
      if (window.location.pathname !== '/') return

      const half = Math.round(window.innerHeight / 2)
      if (window.scrollY > half) {
        if (!logoVisibleRef.current) {
          setShowLogo(true)
          setTimeout(animateLogoEnter, 500)
          hasScrolledPastRef.current = true
        }
      } else if (logoVisibleRef.current) {
        animateLogoExit()
        hasScrolledPastRef.current = false
      }
    }

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('touchmove', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('touchmove', handleScroll)
    }
  }, [])

  // Listen for logo animation event
  useEffect(() => {
    document.addEventListener('EVENTS.SHOW_LOGO', animateLogoEnter)
    document.addEventListener('EVENTS.HIDE_LOGO', animateLogoExit)
    return () => {
      document.removeEventListener('EVENTS.SHOW_LOGO', animateLogoEnter)
      document.removeEventListener('EVENTS.HIDE_LOGO', animateLogoExit)
    }
  }, [])

  // Listen for location event
  useEffect(() => {
    const handleLocationRequest = (e) => {
      setShowAllLocations(e.detail)
    }

    document.addEventListener(
      'EVENTS.TOGGLE_ALL_LOCATIONS',
      handleLocationRequest,
    )
    return () =>
      document.removeEventListener(
        'EVENTS.TOGGLE_ALL_LOCATIONS',
        handleLocationRequest,
      )
  }, [])

  const animateLogoEnter = () => {
    logoVisibleRef.current = true
    const el = document.querySelector('.prg-header-logo-btn')
    if (el) {
      gsap.to(el, {
        opacity: 1,
        duration: 0.1,
        top: '50%',
        ease: 'elastic.out(1, 0.5)',
      })
    }
  }

  const animateLogoExit = () => {
    logoVisibleRef.current = false
    gsap.to('.prg-header-logo-btn', {
      opacity: 0,
      top: '80%',
      duration: 0.2,
      ease: 'elastic.out(1, 0.1)',
      onComplete: () => {
        if (!showLogoRef.current) {
          setShowLogo(false)
        }
      },
    })
  }

  const handleToggleMenu = () => {
    // Close locations
    if (showAllLocations) {
      document.dispatchEvent(new CustomEvent('EVENTS.CLOSE_LOCATION_HEADER'))
    }

    if (showMenu) {
      // Dispatch close event
      if (!restaurant.styles.useExpandedHeader || mobile) {
        document.dispatchEvent(new CustomEvent('EVENTS.CLOSE_MENU'))

        if (!showLogoRef.current && !hasScrolledPastRef.current) {
          animateLogoExit()
        }
      }
    } else {
      setShowMenu(true)
      setShowLogo(true)

      setTimeout(() => {
        animateLogoEnter()
      }, 50)
    }
  }

  const handleLogoClick = () => {
    Utils.maybeShowLoader('/')
    setShowMenu(false)
  }

  useEffect(() => {
    const handleMenuClose = () => {
      setShowMenu(false)
    }

    document.addEventListener('EVENTS.MENU_CLOSED', handleMenuClose)
    return () =>
      document.removeEventListener('EVENTS.MENU_CLOSED', handleMenuClose)
  }, [])

  const handleToggleLocation = () => {
    if (showAllLocations) {
      // Emit close event
      document.dispatchEvent(new CustomEvent('EVENTS.CLOSE_LOCATION_HEADER'))
    } else {
      // Hide menu if it's visible
      if (showMenu) {
        handleToggleMenu()
      }

      setShowAllLocations(true)
    }
  }

  const getHeaderClass = () => {
    const classNameArr = ['prg-header']

    if (
      restaurant.headerItems.includeAlertMessage &&
      restaurant.headerItems.alertMessage
    ) {
      classNameArr.push('with-alert')
    }

    if (location.pathname === '/') {
      classNameArr.push('prg-header-home')
    }

    return classNameArr.join(' ')
  }

  const getLogoMark = () => {
    if (restaurant.headerItems?.headerLogoMark) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: restaurant.headerItems.headerLogoMark,
          }}
        />
      )
    }

    return restaurant.icons.headerLogoIcon
      ? restaurant.icons.headerLogoIcon
      : restaurant.icons.logoIcon
  }

  return (
    <header className={getHeaderClass()}>
      {/* Alert message */}
      {restaurant.headerItems.includeAlertMessage &&
        restaurant.headerItems.alertMessage && (
          <div className="prg-header-alert">
            <HtmlRendererComponent copy={restaurant.headerItems.alertMessage} />
          </div>
        )}
      {/* End alert message */}

      <div className="prg-header-container">
        {(!restaurant.styles.useExpandedHeader || mobile) &&
          !restaurant.styles.disableHeader && (
            <button className="prg-header-nav-btn" onClick={handleToggleMenu}>
              {!showMenu
                ? restaurant.icons.headerMenuIcon
                  ? restaurant.icons.headerMenuIcon
                  : hamburgerIcon
                : closeIcon}
            </button>
          )}
        {showLogo && (
          <Link
            className="prg-header-logo-btn"
            onClick={handleLogoClick}
            to="/"
          >
            {getLogoMark()}
          </Link>
        )}
        {restaurant.selectedLocation && (
          <div className="prg-header-right">
            {restaurant.locations?.length > 1 && (
              <button
                className="prg-location-btn"
                onClick={handleToggleLocation}
              >
                {restaurant.selectedLocation.name}{' '}
                {showAllLocations ? chevronUpIcon : chevronDownIcon}
              </button>
            )}
            {restaurant.selectedLocation.includeReservationLink &&
              restaurant.selectedLocation.reservationLink && (
                <Link
                  className="prg-header-reservation-btn prg-primary-btn outline prg-main-reservation-btn"
                  target="_blank"
                  to={restaurant.selectedLocation.reservationLink}
                >
                  Reservations
                </Link>
              )}
            {!restaurant.styles.disableOnlineOrdering && (
              <button
                className="prg-header-order-btn prg-primary-btn prg-main-order-btn"
                onClick={() =>
                  Utils.handleAction('action:orderOnline', restaurant)
                }
              >
                Order Online
              </button>
            )}
            {itemCount > 0 && (
              <Link
                to="/cart"
                onClick={() => Utils.maybeShowLoader('/cart')}
                className="prg-cart-btn"
              >
                {cartIcon} <span>{itemCount}</span>
              </Link>
            )}
          </div>
        )}
      </div>
      {(showMenu || (restaurant.styles.useExpandedHeader && !mobile)) && (
        <HeaderMenuComponent
          withAlert={
            restaurant.headerItems.includeAlertMessage &&
            restaurant.headerItems.alertMessage
          }
          onClose={() => setShowMenu(false)}
          restaurant={restaurant}
        />
      )}
      {showAllLocations && <LocationHeaderComponent restaurant={restaurant} />}
    </header>
  )
}

export default HeaderComponent
