import { useLayoutEffect, useState } from 'react'
import './product-detail-modal.component.css'
import API from '../../../api'
import { closeIcon, upArrowIcon } from '../../icons'
import { gsap } from 'gsap'
import DropdownComponent from '../dropdown/dropdown.component'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { LOCAL_STORAGE_CART_KEY_PREFIX } from '../../../constants'

const ProductDetailModalComponent = (props) => {
  const { onClose, product, collectionName, restaurantSlug } = props
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [addingToCart, setAddingToCart] = useState()
  const [productInstance, setProductInstance] = useState({ ...product })
  const refEl = useDetectClickOutside({ onTriggered: onClose })

  // Animate in
  useLayoutEffect(() => {
    gsap.fromTo(
      '.prg-product-detail',
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.2,
      },
    )

    gsap.fromTo(
      '.prg-product-detail-content',
      {
        opacity: 0,
        scale: 0.9,
      },
      {
        opacity: 1,
        scale: 1,
        duration: 0.4,
        ease: 'power3.out',
      },
    )
  }, [])

  const makeAddToCartRequest = (variantId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const cartKey = `${LOCAL_STORAGE_CART_KEY_PREFIX}${restaurantSlug}`
        const cartId = window.localStorage.getItem(cartKey)
        const body = {
          collection: collectionName,
          cartId,
          variantId,
        }

        const res = await API.postContent('cart', body)
        if (res.newCartId) {
          // Store cart id in session
          window.localStorage.setItem(cartKey, res.newCartId)
        }

        if (res.numItems) {
          document.dispatchEvent(
            new CustomEvent('EVENTS.CART_UPDATE', {
              detail: res.numItems,
            }),
          )
        }

        resolve()
      } catch (e) {
        reject(e)
      }
    })
  }

  const handleVariantChange = (variant) => {
    setProductInstance({ ...productInstance, selectedVariant: variant })
  }

  const handleAddToCart = async () => {
    try {
      setAddingToCart(true)

      // Add to cart
      await makeAddToCartRequest(productInstance.selectedVariant.id)
      onClose()
      document.dispatchEvent(
        new CustomEvent('EVENTS.SHOW_TOAST', {
          detail: {
            message: `"${productInstance.title}" has been added to your cart!`,
            style: 'success',
          },
        }),
      )
    } catch (e) {
      console.error(e)
      document.dispatchEvent(
        new CustomEvent('EVENTS.SHOW_TOAST', {
          detail: {
            style: 'error',
            message:
              'Uh oh. We had an issue adding that product to your cart. Please try again',
          },
        }),
      )
    } finally {
      setAddingToCart(false)
    }
  }

  const handlePaginateLeft = () => {
    let nextIndex
    if (selectedImageIndex - 1 < 0) {
      nextIndex = productInstance.images.length - 1
    } else {
      nextIndex = selectedImageIndex - 1
    }

    setSelectedImageIndex(nextIndex)
  }

  const handlePaginateRight = () => {
    let nextIndex
    if (selectedImageIndex + 1 > productInstance.images.length - 1) {
      nextIndex = 0
    } else {
      nextIndex = selectedImageIndex + 1
    }

    setSelectedImageIndex(nextIndex)
  }

  const getPaginationIndicators = () => {
    const indicators = []
    for (let i = 0; i < productInstance.images.length; i++) {
      indicators.push(
        <button
          key={i}
          className={selectedImageIndex === i ? 'selected' : ''}
          onClick={() => setSelectedImageIndex(i)}
        >
          <span />
        </button>,
      )
    }

    return indicators
  }

  return (
    <div className="prg-product-detail">
      <div className="prg-product-detail-content" ref={refEl}>
        <button className="prg-product-detail-close-btn" onClick={onClose}>
          {closeIcon}
        </button>
        <div className="prg-product-detail-content-inner">
          <div className="prg-product-detail-left">
            <img
              src={productInstance.images[selectedImageIndex]}
              alt={productInstance.title}
            />
            {productInstance.images.length > 1 && (
              <>
                <button
                  className="prg-product-detail-pagination-btn prg-product-detail-pagination-left-btn"
                  onClick={handlePaginateLeft}
                >
                  {upArrowIcon}
                </button>
                <button
                  className="prg-product-detail-pagination-btn prg-product-detail-pagination-right-btn"
                  onClick={handlePaginateRight}
                >
                  {upArrowIcon}
                </button>
                <div className="prg-product-detail-pagination-indicators">
                  {getPaginationIndicators()}
                </div>
              </>
            )}
          </div>
          <div className="prg-product-detail-right">
            <h2>{productInstance.title}</h2>
            <h5>
              ${parseFloat(productInstance.variants[0].price.amount).toFixed(2)}
            </h5>
            <p>{productInstance.description}</p>
            {productInstance.variants?.length > 1 && (
              <div className="prg-product-detail-variants">
                <DropdownComponent
                  value={productInstance.selectedVariant}
                  placeholder={`Select a ${productInstance.variants[0].selectedOptions[0].name}`}
                  options={productInstance.variants}
                  onChange={handleVariantChange}
                />
              </div>
            )}
            <button
              className="prg-primary-btn"
              onClick={handleAddToCart}
              disabled={
                addingToCart ||
                (product.selectedVariant && product.selectedVariant.disabled) ||
                (productInstance.variants?.length > 1 &&
                  !productInstance.selectedVariant)
              }
            >
              <span>
                {product.selectedVariant?.disabled
                  ? product.selectedVariant.disabledMessage
                  : addingToCart
                  ? 'Adding...'
                  : 'Add to cart'}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductDetailModalComponent
