import './subscription-block.component.css'
import { loadStripe } from '@stripe/stripe-js'
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'
import API from '../../../api'
import { Portal } from 'react-portal'
import { closeIcon } from '../../icons'
import moment from 'moment'

const SubscriptionBlockComponent = (props) => {
  const { stripePublicKey, products } = props
  const [stripePromise] = useState(loadStripe(stripePublicKey))
  const [showModal, setShowModal] = useState()
  const [clientSecret, setClientSecret] = useState()
  const [success, setSuccess] = useState()
  const [showExisting, setShowExisting] = useState()
  const [step, setStep] = useState('login')
  const [error, setError] = useState()
  const [userDetails, setUserDetails] = useState({ email: '', zip: '' })
  const [subscription, setSubscription] = useState([])

  useEffect(() => {
    const handleSubscribe = async (e) => {
      try {
        const { detail } = e
        const prod = products.find((p) => p.productId === detail.productId)
        if (!prod) return

        const returnUrl = `${window.location.href}?return=true`
        const res = await API.postContent('checkout', {
          returnUrl,
          productId: prod.productId,
          price: prod.priceId,
        })

        setClientSecret(res?.client_secret)
        setShowModal(true)
      } catch (e) {
        // todo handle error
      }
    }

    const getSessionStatus = async (sid, pid) => {
      try {
        await API.postContent('checkout/session', {
          sessionId: sid,
          productId: pid,
        })

        // Replace url
        window.history.replaceState({}, '', window.location.pathname)
        setSuccess(true)
      } catch (e) {
        // todo handle error
      }
    }

    const urlParams = new URLSearchParams(window.location.search)
    const sessionId = urlParams.get('session_id')
    const productId = urlParams.get('productId')

    if (sessionId && productId) {
      getSessionStatus(sessionId, productId)
    }

    // Listen for action
    document.addEventListener('EVENTS.ADD_PRODUCT', handleSubscribe)
    return () =>
      document.removeEventListener('EVENTS.ADD_PRODUCT', handleSubscribe)
  }, []) // eslint-disable-line

  const handleDone = () => {
    setSuccess(false)
  }

  const handleLogin = async () => {
    setStep('loading')
    setError(false)

    try {
      const res = await API.postContent('checkout/existing', userDetails)
      setSubscription(res.records)
      setStep('view')
      console.log(res)
    } catch (e) {
      console.log('error fetching existing customer', e)
      setError(true)
      setStep('login')
    }
  }

  const handleClearExisting = () => {
    setShowExisting(false)
    setUserDetails({ email: '', zip: '' })
    setStep('login')
    setError(false)
  }

  return (
    <>
      {showModal && stripePromise && clientSecret && (
        <Portal>
          <div
            className="prg-subscription-modal"
            onClick={() => setShowModal(false)}
          >
            <div
              className="prg-subscription-modal-container"
              onClick={(e) => e.stopPropagation()}
            >
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{ clientSecret }}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            </div>
          </div>
        </Portal>
      )}
      {success && (
        <Portal>
          <div className="prg-subscription-modal">
            <div
              className="prg-subscription-modal-container prg-subscription-result"
              onClick={(e) => e.stopPropagation()}
            >
              <button onClick={() => setSuccess(false)}>{closeIcon}</button>
              <h3>Welcome to the club, {success.customerName}!</h3>
              <h4>
                This is confirmation of your 6 month subscription to LUCA's wine
                club.
              </h4>
              <p>Thanks, we hope to see you in LUCA soon!</p>
              <button className="prg-primary-btn" onClick={handleDone}>
                Done
              </button>
            </div>
          </div>
        </Portal>
      )}

      {/* Past customers login */}
      <div className="prg-existing-login">
        <h4>Existing Members</h4>
        Have a membership? Check your status{' '}
        <button onClick={() => setShowExisting(true)}>here</button>
      </div>

      {showExisting && (
        <Portal>
          <div className="prg-subscription-modal">
            <div
              className="prg-subscription-modal-container prg-subscription-result wide"
              onClick={(e) => e.stopPropagation()}
            >
              <button onClick={handleClearExisting}>{closeIcon}</button>

              {(step === 'login' || step === 'loading') && (
                <>
                  <h3>Welcome back!</h3>
                  <h4>Please enter your email and billing zip code.</h4>
                  <input
                    type="email"
                    placeholder="Email..."
                    value={userDetails.email}
                    onChange={(e) =>
                      setUserDetails({ ...userDetails, email: e.target.value })
                    }
                  />
                  <input
                    type="text"
                    minLength="5"
                    placeholder="5 digit zip code ..."
                    value={userDetails.zip}
                    onChange={(e) =>
                      setUserDetails({ ...userDetails, zip: e.target.value })
                    }
                  />
                  <button
                    className="prg-primary-btn"
                    disabled={!userDetails.zip || !userDetails.email}
                    onClick={handleLogin}
                  >
                    {step === 'login' ? 'Continue' : 'Loading...'}
                  </button>
                  {error && (
                    <h6 className="prg-error">
                      Uh oh. There was an error validating your credentials.
                      Please check and try again
                    </h6>
                  )}
                </>
              )}
              {step === 'view' && subscription && subscription.length > 0 && (
                <>
                  <h3>Welcome back, {subscription[0].customer}!</h3>
                  <h4>Please see your entries below</h4>
                  <h6 className="prg-row header-row">
                    <span className="redemption-status redeemed"></span>
                    Redeemed
                  </h6>
                  <div className="prg-sub-table">
                    <div className="prg-row header-row">
                      <div className="prg-col">Subscription / Period</div>
                      <div className="prg-col half"></div>
                      <div className="prg-col half"></div>
                      <div className="prg-col half"></div>
                      <div className="prg-col half"></div>
                      <div className="prg-col half"></div>
                      <div className="prg-col half"></div>
                    </div>
                    {subscription.map((s) => {
                      return (
                        <div className="prg-row" key={s.id}>
                          <div className="prg-col">
                            {s.product}
                            <br />
                            {moment(s.startDate).format('MM/DD/YYYY')} -{' '}
                            {moment(s.endDate).format('MM/DD/YYYY')}
                          </div>
                          {s.entries.map((e) => {
                            return (
                              <div className="prg-col half" key={e.id}>
                                <span
                                  className={
                                    e.redeemed
                                      ? 'redemption-status redeemed'
                                      : 'redemption-status'
                                  }
                                ></span>{' '}
                                {e.month}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </Portal>
      )}
    </>
  )
}

export default SubscriptionBlockComponent
