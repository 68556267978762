import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useScrollTop = () => {
  const location = useLocation()

  useEffect(() => {
    setTimeout(
      () => window.scrollTo({ top: 0, left: 0, behavior: 'instant' }),
      250,
    )
  }, [location])
}

export default useScrollTop
