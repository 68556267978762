import { useLayoutEffect, useRef } from 'react'
import './page-transition.component.css'
import { gsap } from 'gsap'

const PageTransitionComponent = (props) => {
  const { useExpandedHeader } = props
  const counterRef = useRef(performance.now())

  useLayoutEffect(() => {
    const handleLoader = (e) => {
      const loading = e.detail
      if (!loading) {
        const diff = (performance.now() - counterRef.current) / 1000
        let delay = 0

        if (diff < 0.5) {
          delay = 0.5 - diff
        }

        // Dismiss container
        gsap.to('.prg-transition-container', {
          yPercent: -100,
          duration: 0.5,
          ease: 'power3.out',
          delay,
        })

        // Show content
        const el = document.querySelector('.prg-page-wrapper')
        if (el) {
          gsap.fromTo(
            el,
            {
              opacity: 0,
              yPercent: -2,
            },
            {
              opacity: 1,
              yPercent: 0,
              duration: 0.5,
              ease: 'power2.out',
              delay,
              onComplete: () => {
                setTimeout(() => {
                  if (window.location.pathname !== '/') {
                    document.dispatchEvent(new CustomEvent('EVENTS.SHOW_LOGO'))
                  }
                }, 500)
              },
            },
          )
        } else {
          setTimeout(() => {
            if (window.location.pathname !== '/') {
              document.dispatchEvent(new CustomEvent('EVENTS.SHOW_LOGO'))
            }
          }, 500)
        }
      } else {
        counterRef.current = performance.now()

        // Dismiss current page
        gsap.to('.prg-page-wrapper', {
          opacity: 0,
          duration: 0,
        })

        gsap.to('.prg-transition-container', {
          yPercent: 0,
          duration: 0.6,
          ease: 'power1.in',
        })

        // Hide logo
        if (!useExpandedHeader) {
          document.dispatchEvent(new CustomEvent('EVENTS.HIDE_LOGO'))
        }
      }
    }

    document.addEventListener('EVENTS.SHOW_LOADER', handleLoader)

    return () =>
      document.removeEventListener('EVENTS.SHOW_LOADER', handleLoader)
  }, [useExpandedHeader])

  return <div className="prg-transition-container" />
}

export default PageTransitionComponent
