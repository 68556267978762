export const closeIcon = (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5785 0.435912C18.0165 -0.12609 17.1086 -0.12609 16.5466 0.435912L9.5 7.46815L2.45336 0.421502C1.89135 -0.140501 0.983504 -0.140501 0.421502 0.421502C-0.140501 0.983504 -0.140501 1.89135 0.421502 2.45336L7.46815 9.5L0.421502 16.5466C-0.140501 17.1086 -0.140501 18.0165 0.421502 18.5785C0.983504 19.1405 1.89135 19.1405 2.45336 18.5785L9.5 11.5319L16.5466 18.5785C17.1086 19.1405 18.0165 19.1405 18.5785 18.5785C19.1405 18.0165 19.1405 17.1086 18.5785 16.5466L11.5319 9.5L18.5785 2.45336C19.1261 1.90576 19.1261 0.983504 18.5785 0.435912Z"
      fill="white"
    />
  </svg>
)

export const hamburgerIcon = (
  <svg width="25" height="22" viewBox="0 0 25 22" fill="none">
    <rect y="0.5" width="25" height="3" rx="1.5" fill="white" />
    <rect y="9.5" width="25" height="3" rx="1.5" fill="white" />
    <rect y="18.5" width="25" height="3" rx="1.5" fill="white" />
  </svg>
)

export const twitterIcon = (
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none">
    <path
      d="M20 2.39355C19.2627 2.71579 18.4724 2.93215 17.6416 3.03035C18.4896 2.53011 19.1411 1.73832 19.4467 0.796154C18.6548 1.25803 17.7741 1.59408 16.8405 1.77515C16.0923 0.989498 15.0261 0.5 13.8462 0.5C11.5798 0.5 9.74203 2.30761 9.74203 4.53875C9.74203 4.85485 9.77944 5.16328 9.84958 5.45943C6.43909 5.29064 3.41672 3.68251 1.39194 1.23808C1.03811 1.83653 0.837035 2.53011 0.837035 3.26973C0.837035 4.67071 1.56028 5.9075 2.6623 6.63331C1.98893 6.61182 1.35609 6.42922 0.802743 6.12693C0.802743 6.14381 0.802743 6.16069 0.802743 6.17757C0.802743 8.13556 2.21807 9.76671 4.09321 10.1396C3.75029 10.2317 3.38711 10.2808 3.01302 10.2808C2.74803 10.2808 2.49084 10.2562 2.23989 10.2071C2.76362 11.8106 4.2787 12.9814 6.07279 13.0137C4.66994 14.097 2.90079 14.7415 0.97732 14.7415C0.645312 14.7415 0.319539 14.7231 0 14.6847C1.81436 15.8325 3.97163 16.5 6.28945 16.5C13.8368 16.5 17.9643 10.3452 17.9643 5.0083C17.9643 4.83337 17.9596 4.6569 17.9534 4.48504C18.7546 3.91575 19.4498 3.20375 20 2.39355Z"
      fill="white"
    />
  </svg>
)

export const igIcon = (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path
      d="M5.61364 0C2.5175 0 0 2.5175 0 5.61364V13.3864C0 16.4825 2.5175 19 5.61364 19H13.3864C16.4825 19 19 16.4825 19 13.3864V5.61364C19 2.5175 16.4825 0 13.3864 0H5.61364ZM14.6818 3.45455C15.1568 3.45455 15.5455 3.84318 15.5455 4.31818C15.5455 4.79318 15.1568 5.18182 14.6818 5.18182C14.2068 5.18182 13.8182 4.79318 13.8182 4.31818C13.8182 3.84318 14.2068 3.45455 14.6818 3.45455ZM9.5 4.75C12.1211 4.75 14.25 6.87886 14.25 9.5C14.25 12.1211 12.1211 14.25 9.5 14.25C6.87886 14.25 4.75 12.1211 4.75 9.5C4.75 6.87886 6.87886 4.75 9.5 4.75ZM9.5 6C7.35818 6 6 7.35818 6 9.5C6 11.6418 7.35818 13 9.5 13C11.6418 13 13 11.6418 13 9.5C13 7.35818 11.6418 6 9.5 6Z"
      fill="white"
    />
  </svg>
)

export const emailIcon = (
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none">
    <path
      d="M18 0.5H2C0.9 0.5 0.00999999 1.4 0.00999999 2.5L0 14.5C0 15.6 0.9 16.5 2 16.5H18C19.1 16.5 20 15.6 20 14.5V2.5C20 1.4 19.1 0.5 18 0.5ZM17.6 4.75L10.53 9.17C10.21 9.37 9.79 9.37 9.47 9.17L2.4 4.75C2.15 4.59 2 4.32 2 4.03C2 3.36 2.73 2.96 3.3 3.31L10 7.5L16.7 3.31C17.27 2.96 18 3.36 18 4.03C18 4.32 17.85 4.59 17.6 4.75Z"
      fill="white"
    />
  </svg>
)

export const fbIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#fff">
    <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" />
  </svg>
)

export const chevronDownIcon = (
  <svg width="16" height="16" fill="#fff" viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
    />
  </svg>
)

export const chevronUpIcon = (
  <svg width="16" height="16" fill="#fff" viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
    />
  </svg>
)

export const sortIcon = (
  <svg viewBox="0 0 24 24" fill="none">
    <g>
      <path
        d="M22 7L2 7"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinecap="round"
      ></path>
      <path
        d="M19 12L5 12"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinecap="round"
      ></path>
      <path
        d="M16 17H8"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinecap="round"
      ></path>
    </g>
  </svg>
)

export const upArrowIcon = (
  <svg viewBox="0 0 24 24" fill="none">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C12.2652 3 12.5196 3.10536 12.7071 3.29289L19.7071 10.2929C20.0976 10.6834 20.0976 11.3166 19.7071 11.7071C19.3166 12.0976 18.6834 12.0976 18.2929 11.7071L13 6.41421V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V6.41421L5.70711 11.7071C5.31658 12.0976 4.68342 12.0976 4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929L11.2929 3.29289C11.4804 3.10536 11.7348 3 12 3Z"
        fill="#000000"
      ></path>
    </g>
  </svg>
)

export const downArrowIcon = (
  <svg viewBox="0 0 24 24" fill="none">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C12.5523 3 13 3.44772 13 4V17.5858L18.2929 12.2929C18.6834 11.9024 19.3166 11.9024 19.7071 12.2929C20.0976 12.6834 20.0976 13.3166 19.7071 13.7071L12.7071 20.7071C12.3166 21.0976 11.6834 21.0976 11.2929 20.7071L4.29289 13.7071C3.90237 13.3166 3.90237 12.6834 4.29289 12.2929C4.68342 11.9024 5.31658 11.9024 5.70711 12.2929L11 17.5858V4C11 3.44772 11.4477 3 12 3Z"
        fill="#000000"
      ></path>
    </g>
  </svg>
)

export const cartIcon = (
  <svg viewBox="0 0 24 24">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5285 6C16.5098 5.9193 16.4904 5.83842 16.4701 5.75746C16.2061 4.70138 15.7904 3.55383 15.1125 2.65C14.4135 1.71802 13.3929 1 12 1C10.6071 1 9.58648 1.71802 8.88749 2.65C8.20962 3.55383 7.79387 4.70138 7.52985 5.75747C7.50961 5.83842 7.49016 5.9193 7.47145 6H5.8711C4.29171 6 2.98281 7.22455 2.87775 8.80044L2.14441 19.8004C2.02898 21.532 3.40238 23 5.13777 23H18.8622C20.5976 23 21.971 21.532 21.8556 19.8004L21.1222 8.80044C21.0172 7.22455 19.7083 6 18.1289 6H16.5285ZM8 11C8.57298 11 8.99806 10.5684 9.00001 9.99817C9.00016 9.97438 9.00044 9.9506 9.00084 9.92682C9.00172 9.87413 9.00351 9.79455 9.00718 9.69194C9.01451 9.48652 9.0293 9.18999 9.05905 8.83304C9.08015 8.57976 9.10858 8.29862 9.14674 8H14.8533C14.8914 8.29862 14.9198 8.57976 14.941 8.83305C14.9707 9.18999 14.9855 9.48652 14.9928 9.69194C14.9965 9.79455 14.9983 9.87413 14.9992 9.92682C14.9996 9.95134 14.9999 9.97587 15 10.0004C15 10.0004 15 11 16 11C17 11 17 9.99866 17 9.99866C16.9999 9.9636 16.9995 9.92854 16.9989 9.89349C16.9978 9.829 16.9957 9.7367 16.9915 9.62056C16.9833 9.38848 16.9668 9.06001 16.934 8.66695C16.917 8.46202 16.8953 8.23812 16.8679 8H18.1289C18.6554 8 19.0917 8.40818 19.1267 8.93348L19.86 19.9335C19.8985 20.5107 19.4407 21 18.8622 21H5.13777C4.55931 21 4.10151 20.5107 4.13998 19.9335L4.87332 8.93348C4.90834 8.40818 5.34464 8 5.8711 8H7.13208C7.10465 8.23812 7.08303 8.46202 7.06595 8.66696C7.0332 9.06001 7.01674 9.38848 7.00845 9.62056C7.0043 9.7367 7.00219 9.829 7.00112 9.89349C7.00054 9.92785 7.00011 9.96221 7 9.99658C6.99924 10.5672 7.42833 11 8 11ZM9.53352 6H14.4665C14.2353 5.15322 13.921 4.39466 13.5125 3.85C13.0865 3.28198 12.6071 3 12 3C11.3929 3 10.9135 3.28198 10.4875 3.85C10.079 4.39466 9.76472 5.15322 9.53352 6Z"
        fill="#000000"
      ></path>
    </g>
  </svg>
)
