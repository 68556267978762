import './scroller-content.component.css'
import API from '../../../api'

const ScrollerContentComponent = (props) => {
  const { heading, subHeading, style, itemWidth, items } = props

  return (
    <div className={`prg-scroller-content style-${style}`}>
      <div className="prg-scroller-content-container">
        {heading && <h1>{heading}</h1>}
        {subHeading && <h2>{subHeading}</h2>}
        <div className="prg-scroller-content-items">
          {items.map((i) => {
            const { heading, subHeading, image, svgImage, copy, id } = i
            return (
              <div
                key={id}
                className="prg-scroller-content-item"
                style={{ width: `${itemWidth}%` }}
              >
                <div className="prg-scroller-content-item-meta">
                  {heading && <h3>{heading}</h3>}
                  {subHeading && <h4>{subHeading}</h4>}
                  <div className="prg-scroller-content-item-image-copy">
                    {svgImage && (
                      <div
                        className="prg-scroller-content-item-svg"
                        dangerouslySetInnerHTML={{ __html: svgImage }}
                      />
                    )}
                    {image && (
                      <img
                        src={API.getMediaAssetUrl(image.url)}
                        alt={image.altText}
                      />
                    )}
                    {copy && <p>{copy}</p>}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ScrollerContentComponent
