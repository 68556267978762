import { useCallback, useEffect } from 'react'
import './location-header.component.css'
import { gsap } from 'gsap'
import Session from '../../../session'

const LocationHeaderComponent = (props) => {
  const { restaurant } = props

  useEffect(() => {
    gsap.fromTo(
      '.prg-location-header-container',
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.3,
      },
    )

    gsap.fromTo(
      '.prg-location-header-content-wrapper',
      {
        opacity: 0,
        yPercent: -20,
        scaleY: 0.8,
      },
      {
        opacity: 1,
        yPercent: 0,
        duration: 0.4,
        delay: 0.1,
        scaleY: 1,
        ease: 'power3.inOut',
      },
    )
  }, [])

  const handleClose = useCallback(() => {
    gsap.to('.prg-location-header-content-wrapper', {
      opacity: 0,
      yPercent: -10,
      scaleY: 0.9,
      duration: 0.2,
    })

    gsap.to('.prg-location-header-container', {
      opacity: 0,
      duration: 0.3,
      delay: 0.1,
      onComplete: () => {
        document.dispatchEvent(
          new CustomEvent('EVENTS.TOGGLE_ALL_LOCATIONS', { detail: false }),
        )
      },
    })
  }, [])

  // Listen for close event
  useEffect(() => {
    document.addEventListener('EVENTS.CLOSE_LOCATION_HEADER', handleClose)
    return () =>
      document.removeEventListener('EVENTS.CLOSE_LOCATION_HEADER', handleClose)
  }, [handleClose])

  // Listen for scroll change event
  useEffect(() => {
    document.addEventListener('scroll', handleClose)
    return () => document.removeEventListener('scroll', handleClose)
  }, [handleClose])

  const handleCreateLocationsList = () => {
    const locations = restaurant.locations.map((l) => {
      const className =
        restaurant.selectedLocation.id === l.id ? 'selected' : ''
      return (
        <li
          key={l.id}
          onClick={() => handleLocationSelection(l)}
          className={className}
        >
          <h6>{l.name}</h6>
          <p>{l.address}</p>
        </li>
      )
    })

    return locations
  }

  const handleLocationSelection = (l) => {
    // Close modal
    document.dispatchEvent(new CustomEvent('EVENTS.CLOSE_LOCATION_HEADER'))

    // Set selected
    restaurant.setRestaurant({ ...restaurant, selectedLocation: l })

    // Write to session
    const session = Session.read()
    const val = session ? session : {}
    val[restaurant.slug] = l.id
    Session.write(val)
  }

  return (
    <div className="prg-location-header-container" onClick={handleClose}>
      <div
        className="prg-location-header-content-wrapper"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="prg-location-header-content">
          <h3>Select a location</h3>
          <ul className="prg-location-selector-list">
            {handleCreateLocationsList()}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default LocationHeaderComponent
