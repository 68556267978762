import { Link } from 'react-router-dom'
import './header-menu.component.css'
import { useCallback, useEffect, useRef } from 'react'
import useMeasure from 'react-use-measure'
import Utils from '../../../utils'
import { gsap } from 'gsap'
import useCheckForMobileHook from '../../../hooks/useCheckForMobile'

const HeaderMenuComponent = (props) => {
  const { restaurant, withAlert } = props
  const [headerRef, { height }] = useMeasure()
  const didAnimateRef = useRef()
  const mobile = useCheckForMobileHook()

  const handleClose = useCallback(
    (slug, useAsRedirect) => {
      if (!useAsRedirect && slug) {
        Utils.maybeShowLoader(slug)
      }

      // Animate out if restaurant sets it
      if (!restaurant.styles.useExpandedHeader || mobile) {
        gsap.to('.prg-header-expanded', {
          height: 0,
          duration: 0.4,
          ease: 'power3.inOut',
          delay: 0.1,
          onComplete: () => {
            document.dispatchEvent(new CustomEvent('EVENTS.MENU_CLOSED'))
          },
        })

        gsap.to('.prg-header-expanded li', {
          yPercent: -100,
          opacity: 0,
          stagger: 0.05,
          duration: 0.4,
          ease: 'power3.inOut',
        })
      }
    },
    [restaurant, mobile],
  )

  // Listen for close event
  useEffect(() => {
    const handleCloseEvent = () => {
      handleClose()
      didAnimateRef.current = false
    }

    document.addEventListener('EVENTS.CLOSE_MENU', handleCloseEvent)
    return () =>
      document.removeEventListener('EVENTS.CLOSE_MENU', handleCloseEvent)
  }, [handleClose])

  // Animate in
  useEffect(() => {
    const {
      useExpandedHeader,
      headerContainerAnimation,
      headerAnimation: { from, to, delay },
    } = restaurant.styles
    const theDelay = useExpandedHeader && !mobile ? delay : 0
    const containerEase = headerContainerAnimation?.ease || 'elastic.out(1,0.6)'
    const containerDur = headerContainerAnimation?.duration || 1

    const animateIn = () => {
      gsap.fromTo(
        '.prg-header-expanded',
        {
          height: 0,
        },
        {
          height,
          duration: containerDur,
          ease: containerEase,
          onComplete: () => {
            didAnimateRef.current = true
          },
        },
      )

      gsap.fromTo('.prg-header-expanded li', from, to)
    }

    if (didAnimateRef.current) return
    if (useExpandedHeader) {
      gsap.set('.prg-header-expanded li', from)
    }
    setTimeout(animateIn, theDelay)
  }, [height, restaurant, mobile])

  return (
    <div
      className={
        withAlert
          ? 'prg-header-expanded-wrapper with-alert'
          : 'prg-header-expanded-wrapper'
      }
    >
      <div className="prg-header-expanded">
        <div className="prg-header-expanded-container" ref={headerRef}>
          <ul className="prg-header-expanded-nav">
            {restaurant.headerItems?.menu?.map((item) => {
              const { id, useAsRedirect, redirectUrl, slug, title } = item
              const toPath =
                useAsRedirect && redirectUrl ? redirectUrl : `/${slug}`
              const isSelected = window.location.pathname === toPath
              return (
                <li key={id} className={isSelected ? 'selected' : ''}>
                  <Link
                    target={useAsRedirect ? '_blank' : '_self'}
                    to={toPath}
                    onClick={() => handleClose(slug, useAsRedirect)}
                  >
                    {title}
                  </Link>
                </li>
              )
            })}
            {restaurant.selectedLocation?.includeReservationLink &&
              restaurant.selectedLocation?.reservationLink && (
                <li className="mobile-only">
                  <Link
                    className="prg-header-reservation-btn prg-primary-btn outline"
                    target="_blank"
                    to={restaurant.selectedLocation.reservationLink}
                  >
                    Reservations
                  </Link>
                </li>
              )}
            {!restaurant.styles.disableOnlineOrdering &&
              restaurant.selectedLocation && (
                <li className="mobile-only">
                  <button
                    className="prg-header-order-btn prg-primary-btn"
                    onClick={() =>
                      Utils.handleAction('action:orderOnline', restaurant)
                    }
                  >
                    Order Online
                  </button>
                </li>
              )}
          </ul>
          {restaurant.headerItems?.socialLinks?.length > 0 && (
            <ul className="prg-header-expanded-social-nav">
              {restaurant.headerItems.socialLinks.map((item) => {
                return (
                  <li key={item.id}>
                    <a href={item.link} target="_blank" rel="noreferrer">
                      {Utils.getIconFromName(item.icon)}
                    </a>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default HeaderMenuComponent
