import './quote-block.component.css'

const QuoteBlockComponent = (props) => {
  const { quote, author, title } = props

  return (
    <div className="prg-quote-block">
      <div className="prg-quote-block-container">
        <h1>{quote}</h1>
        {author && <h5>{author}</h5>}
        {title && <h6>{title}</h6>}
      </div>
    </div>
  )
}

export default QuoteBlockComponent
