import './call-to-action-row.component.css'
import Utils from '../../../utils'
import { useNavigate } from 'react-router-dom'

const CallToActionRowComponent = (props) => {
  const { heading, brandStyle, buttonLabel, buttonPageLink, restaurant } = props
  const navigate = useNavigate()

  return (
    <div className={`prg-cta-row style-${brandStyle}`}>
      <div className="prg-cta-row-content">
        <div className="prg-cta-row-heading">{heading}</div>
        <button
          className="prg-primary-btn"
          onClick={() =>
            Utils.handleAction(buttonPageLink, restaurant, navigate)
          }
        >
          {buttonLabel}
        </button>
      </div>
    </div>
  )
}

export default CallToActionRowComponent
