import { useEffect, useState, Fragment } from 'react'
import './menu-list.component.css'

const MenuListComponent = (props) => {
  const { restaurant, includeAllRestaurantMenus, menusToInclude } = props
  const [selectedMenu, setSelectedMenu] = useState()
  const [menusToDisplay, setMenusToDisplay] = useState([])

  useEffect(() => {
    // Create menus list
    let menuArr
    if (!includeAllRestaurantMenus && menusToInclude) {
      menuArr = menusToInclude
    } else {
      menuArr = restaurant.menus
    }

    // Filter more
    if (
      restaurant.selectedLocation?.doesNotServeAllRestaurantMenus &&
      restaurant.selectedLocation?.menusNotServed?.length > 0
    ) {
      const menusNotServedIds = restaurant.selectedLocation.menusNotServed.map(
        (m) => m.id,
      )
      menuArr = menuArr.filter((m) => menusNotServedIds.indexOf(m.id) < 0)
    }

    // Set menu list
    setMenusToDisplay(menuArr)

    // Set selected menu if we have params
    const search = new URLSearchParams(window.location.search)
    let theMenu = menuArr[0]
    const searchTerm = search.get('m')
    if (searchTerm && searchTerm !== undefined) {
      const menuSearch = menuArr.find((m) => m.id === searchTerm)
      if (menuSearch) {
        theMenu = menuSearch
      }
    }

    setSelectedMenu(theMenu)
  }, [restaurant, includeAllRestaurantMenus, menusToInclude])

  const getAsteriskElements = (numberOfAsterisks = 1) => {
    let str = ''
    for (let i = 0; i < numberOfAsterisks; i++) {
      str += '*'
    }

    return <i>{str}</i>
  }

  // Set menu border width
  useEffect(() => {
    const expandBorder = () => {
      const subMenuExtras = document.getElementById('submenu-extras')
      const mainMenuExtras = document.getElementById('menu-extras')
      if (subMenuExtras) {
        const subItems = document.querySelectorAll('#submenu-extras li')
        let subWidth = 0
        subItems.forEach(
          (s) =>
            (subWidth = Math.max(subWidth, s.getBoundingClientRect().width)),
        )

        const pseudo = document.querySelector('#submenu-extras span')
        if (pseudo) {
          pseudo.style.width = `${subWidth + 32}px`
        }
      }

      if (mainMenuExtras) {
        const items = document.querySelectorAll('#menu-extras li')
        let itemWidth = 0
        items.forEach(
          (s) =>
            (itemWidth = Math.max(itemWidth, s.getBoundingClientRect().width)),
        )

        const pseudo = document.querySelector('#menu-extras span')
        if (pseudo) {
          pseudo.style.width = `${itemWidth + 32}px`
        }
      }
    }

    if (restaurant.styles.useMenuExtrasBorders) {
      setTimeout(expandBorder)
    }
  }, [restaurant.styles, selectedMenu])

  if (!selectedMenu) return null

  return (
    <div className="prg-menu-list">
      <div className="prg-menu-list-container">
        <h3>Menu</h3>
        <ul className="prg-menu-options">
          {menusToDisplay.map((m) => {
            return (
              <li key={m.id}>
                <button
                  onClick={() => setSelectedMenu(m)}
                  className={selectedMenu.id === m.id ? 'selected' : ''}
                >
                  {m.name}
                </button>
              </li>
            )
          })}
        </ul>
        {selectedMenu.includeSubHeading && selectedMenu.subHeading && (
          <h4 className={selectedMenu.subHeadingStyle}>
            {selectedMenu.subHeading}
          </h4>
        )}
        {selectedMenu.includeIntroSvg && selectedMenu.introSvg && (
          <div
            className="prg-menu-intro-svg"
            dangerouslySetInnerHTML={{ __html: selectedMenu.introSvg }}
          />
        )}
        {selectedMenu.items?.length > 0 && (
          <ul
            className={`prg-menu-items prg-menu-id-${selectedMenu.id}`}
            id="menu"
          >
            {selectedMenu.items?.map((i) => {
              const {
                id,
                name,
                description,
                includeAsterisk,
                numberOfAsterisks,
                includeSvgIcon,
                svgIcon,
              } = i
              return (
                <li key={id}>
                  <div className="prg-menu-item-desc">
                    <h6>
                      {name}{' '}
                      {includeAsterisk &&
                        getAsteriskElements(numberOfAsterisks)}
                      {includeSvgIcon && (
                        <div
                          className="prg-menu-item-icon"
                          dangerouslySetInnerHTML={{ __html: svgIcon }}
                        />
                      )}
                    </h6>
                    <p>{description}</p>
                  </div>
                </li>
              )
            })}
          </ul>
        )}
        {selectedMenu.includeSubMenus && (
          <>
            {selectedMenu.subMenus.map((s) => {
              const { id, subMenuName, subMenuItems, includeExtras, extras } = s
              return (
                <Fragment key={id}>
                  <h3>{subMenuName}</h3>
                  <ul className={`prg-menu-items prg-menu-id-${id}`} id={id}>
                    {subMenuItems?.map((i) => {
                      const {
                        id,
                        name,
                        description,
                        includeAsterisk,
                        numberOfAsterisks,
                      } = i
                      return (
                        <li key={id}>
                          <div className="prg-menu-item-desc">
                            <h6>
                              {name}{' '}
                              {includeAsterisk &&
                                getAsteriskElements(numberOfAsterisks)}
                            </h6>
                            <p>{description}</p>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                  {includeExtras && extras?.length > 0 && (
                    <>
                      <ul className="prg-menu-item-extras" id="submenu-extras">
                        <span />
                        {extras?.map((i) => {
                          const { id, label, includeSvgIcon, svgIcon } = i
                          return (
                            <li key={id}>
                              {includeSvgIcon && svgIcon && (
                                <div
                                  className="prg-menu-item-icon"
                                  dangerouslySetInnerHTML={{ __html: svgIcon }}
                                />
                              )}
                              {label}
                            </li>
                          )
                        })}
                      </ul>
                    </>
                  )}
                </Fragment>
              )
            })}
          </>
        )}
        {selectedMenu.includeExtras && selectedMenu.extras?.length > 0 && (
          <div className={`prg-menu-extras ${selectedMenu.extrasStyle}`}>
            {selectedMenu.extrasHeading && (
              <h4>{selectedMenu.extrasHeading}</h4>
            )}
            <ul className="prg-menu-item-extras" id="menu-extras">
              <span />
              {selectedMenu.extras?.map((i) => {
                const { id, label, includeSvgIcon, svgIcon } = i
                return (
                  <li key={id}>
                    {includeSvgIcon && svgIcon && (
                      <div
                        className="prg-menu-item-icon"
                        dangerouslySetInnerHTML={{ __html: svgIcon }}
                      />
                    )}
                    {label}
                  </li>
                )
              })}
            </ul>
          </div>
        )}
        {selectedMenu.includeSvg && selectedMenu.svg && (
          <div
            className="prg-menu-post-svg"
            dangerouslySetInnerHTML={{ __html: selectedMenu.svg }}
          />
        )}
      </div>
    </div>
  )
}

export default MenuListComponent
