import { Link } from 'react-router-dom'
import './menu-selector.component.css'
import Utils from '../../../utils'

const MenuSelectorComponent = (props) => {
  const { menus, menuPage } = props

  return (
    <div className="prg-menu-selector-container">
      <div className="prg-menu-selector">
        <h1>Menus</h1>
        <ul>
          {menus?.map((m) => {
            return (
              <li key={m.id}>
                <Link
                  className="prg-primary-btn outline"
                  to={`/${menuPage.slug}?m=${m.id}`}
                  onClick={() => Utils.maybeShowLoader(menuPage.slug)}
                >
                  {m.name}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default MenuSelectorComponent
