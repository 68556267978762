import HtmlRendererComponent from '../html-renderer/html-renderer.component'
import './events-list.component.css'

const EventsListComponent = (props) => {
  const { restaurant } = props

  return (
    <div className="prg-events-list">
      <ul className="prg-events-list-container">
        {restaurant.events?.map((e) => {
          return (
            <li key={e.id}>
              {e.title && <h2>{e.title}</h2>}
              <p>
                {e.description && (
                  <HtmlRendererComponent copy={e.description} />
                )}
              </p>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default EventsListComponent
