import API from '../../../api'
import './image-block.component.css'

const ImageBlockComponent = (props) => {
  const { image, setWidth, width } = props
  const { url, altText } = image

  const getImageStyle = () => {
    if (setWidth && width) {
      return { width, margin: '0 auto' }
    }

    return {}
  }

  return (
    <div className="prg-image-block" style={getImageStyle()}>
      <img src={API.getMediaAssetUrl(url)} alt={altText} />
    </div>
  )
}

export default ImageBlockComponent
